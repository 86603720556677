export const ActivationCodeNS = "ActivationCode"
export const AlertNS = "Alert"
export const CguNS = "cgu"
export const CongratsNS = "Congratulation"
export const DeleteAccountNS = "deleteAccount"
export const EvaluationNS = "Evaluation"
export const ExerciseNS = "Exercise"
export const ExercisesListNS = "ExercisesList"
export const FaqNS = "Faq"
export const HomeNS = "Home"
export const JourneyNS = "Journey"
export const LanguageNS = "Language"
export const MainNS = "Main"
export const NavigationNS = "Navigation"
export const OperationNS = "Operation"
export const PatientNS = "Patient"
export const PatientsListNS = "PatientsList"
export const PracticianListNS = "PatientsList"
export const PractitionerHomeNS = "PractitionerHome"
export const PraticienNS = "Praticien"
export const ProfileNS = "Profile"
export const ProtocolNS = "Protocol"
export const PromsNS = "Proms"
export const SettingsNS = "Settings"
export const SMSValidationNS = "SMSValidation"
export const protocolEnd = "protocolEnd"
export const VideoNS = "Video"
