import { AddressType } from "../features/models/Address"
import { Role, SpecialtyName } from "./CommonUserData"

export interface DevParams {
  dev?: boolean
}

export interface OnCheckActivationCodeParams extends DevParams {
  role: Role
  code: string
  phone: string
  timezone: string | undefined
  specialties?: SpecialtyName[]
}

export interface OnConvertAddressParams extends DevParams {
  address: AddressType
}

export interface OnArchivePatientParams extends DevParams {
  patientId: string
}

export interface OnUpdatePatientsRequests extends DevParams {
  practician: string
  patient: string
  practicianMail: string
  practicianLng: string
  patientName: string
  isCancel?: boolean
}

export interface OnHandlePatientsRequests extends DevParams {
  practician: string
  patient: string
  validate?: boolean
}

export interface OnCreateTokenParams extends DevParams {
  isAuth: boolean
  refreshToken: string
}

export type ISO8601date = string
export type ISO8601time = string

export const collections = {
  // OnBoarding - Server
  LOGIN: "login",
  CODES: "code",
  PACKS: "pack",
  SECURITY: "code_security",
  // Read-only
  FAQ: "faq",
  TODO: "todo",
  NOT_TODO: "notTodo",
  SPECIALTIES: "specialties",
  ORGANS: "organs",
  PHASES: "phases",
  EELS: "eels",
  EXERCISES: "exercises",
  EQUIPMENTS: "equipments",
  PROGRAMS: "programs",
  PATHOLOGIES: "pathologies",
  PATHOLOGIES_ORGAN: "organ",
  ORGANS_SPECIALTY: "specialty",
  // Patient
  MESSAGES: "messages",
  SESSIONS: "sessions",
  EVAL_MORNING: "evalMorning",
  EVAL_EVENING: "evalEvening",
  // PROMS
  PROMS: "proms",
  FORMS: "norForms",
  SCORES: "scores",
  REVIEW_URLS: "reviewUrls",
  // Sign in forms
  SIGNIN_FORMS: "signinForm",
  SIGNIN_QUESTIONS: "signinForm_questions",
  SIGNIN_PARAMETERS: "signinForm_parameters",
  // Practitioner
  QCU: "norQcu",
  INSTRUCTIONS: "instructions",
  APPS: "apps",
} as const

export type CollectionNames = typeof collections[keyof typeof collections]

// When the firestore came from imported json file, the Timestamp is not recognize. We need to create one
export type JsonTimestamp = { _seconds: number; _nanoseconds: number }

export const webAppUrl = "https://app.doctup.fr"
