import { DateTimePicker, FormikTextInput, Subtitle1, useTheme, VectorIconProps } from "capsule"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet, View } from "react-native"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"
import { TextInput } from "react-native-paper"

import OppositeRadioButton from "../../components/OppositeRadioButton"
import SubmitButton from "../../components/SubmitButton"
import { ICON_SIZE, SCROLL_HEIGHT } from "../../features/config/Constants"
import { ProfileNS } from "../../features/i18n/constants"
import { getUtcDate } from "../../utils/conversion"
import { ProfileFormValues } from "./Constants"

interface IProps {
  isEdit: boolean
}

/**
 * child style : global view (input + helperText or other element)
 * style/touchableStyle: input or touchableElem
 * Need to separate the style of the global view to avoid shadow cut, loosing auto-focus
 * to keep dismiss keyboard action,
 * */
const ProfileScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    dimensions: { spacing },
    colors: {
      surface,
      black: { highEmphasis, mediumEmphasis },
    },
  } = useTheme()
  const { t } = useTranslation(ProfileNS)
  const { values } = useFormikContext<ProfileFormValues>()
  const currentDate = useMemo(() => getUtcDate(undefined, true), [])
  const s = useMemo(
    () => ({
      // view and container style
      content: [
        styles.content,
        {
          paddingTop: spacing,
          backgroundColor: surface.background,
        },
      ],
      innerContent: {
        paddingBottom: spacing * 2,
      },
      inputContainer: {
        paddingHorizontal: spacing,
        backgroundColor: surface.background,
      },
      // children styles
      child: { paddingBottom: spacing },
      innerChild: [styles.content, { paddingBottom: spacing }],
      input: [
        styles.input,
        {
          paddingLeft: spacing / 4,
        },
      ],
      dateInput: [styles.input, { paddingLeft: spacing / 2, backgroundColor: surface.textInput }],
      label: {
        paddingBottom: spacing / 4,
      },
      affix: {
        color: highEmphasis,
      },
      sizeView: {
        paddingRight: spacing / 2,
      },
      weightView: {
        paddingLeft: spacing / 2,
      },
    }),

    [spacing, surface.textInput, highEmphasis, surface.background],
  )

  const renderIcon = useCallback(
    (name: string) =>
      ({
        name,
        category: "MaterialIcons",
        size: ICON_SIZE,
        color: mediumEmphasis,
      } as VectorIconProps),
    [mediumEmphasis],
  )
  return (
    <KeyboardAwareScrollView
      style={s.content}
      enableOnAndroid
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={s.innerContent}
      extraScrollHeight={Platform.OS === "ios" ? SCROLL_HEIGHT : undefined}
    >
      <View style={s.inputContainer}>
        <FormikTextInput
          style={s.input}
          name="lastName"
          autoComplete="off"
          autoCorrect={false}
          textContentType="none"
          spellCheck={false}
          styles={{ child: s.child }}
          label={t("label.lastName")}
        />
        <FormikTextInput
          style={s.input}
          name="firstName"
          autoComplete="off"
          autoCorrect={false}
          textContentType="none"
          spellCheck={false}
          styles={{ child: s.child }}
          label={t("label.firstName")}
        />
        <Subtitle1 style={s.label}>{t("label.gender")}</Subtitle1>
        <OppositeRadioButton
          name="gender"
          left={{ value: "female", label: t("label.female") }}
          right={{ value: "male", label: t("label.male") }}
          viewStyle={s.child}
        />
        <DateTimePicker
          mode="date"
          name="birthdate"
          styles={{ child: s.child }}
          touchableStyle={s.dateInput}
          label={t("label.birthdate")}
          icons={{ left: renderIcon("calendar-today"), right: renderIcon("arrow-drop-down") }}
          initialValue={values.birthdate ?? currentDate}
          noAdditionalValue
        />
        <View style={styles.form}>
          <FormikTextInput
            name="size"
            style={s.input}
            viewStyle={s.sizeView}
            keyboardType="numeric"
            label={t("label.size")}
            styles={{ child: s.innerChild }}
            right={<TextInput.Affix text={t("label.cm")} textStyle={s.affix} />}
          />
          <FormikTextInput
            name="weight"
            style={s.input}
            keyboardType="numeric"
            viewStyle={s.weightView}
            label={t("label.weight")}
            styles={{ child: s.innerChild }}
            right={<TextInput.Affix text={t("label.kg")} textStyle={s.affix} />}
          />
        </View>
      </View>
      <SubmitButton<ProfileFormValues> {...{ isEdit }} />
    </KeyboardAwareScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
  },
  form: {
    flexDirection: "row",
  },
  input: {
    height: 50,
    borderRadius: 0,
  },
})

export default ProfileScreen
