import { Body2, firestore, IUserContext, SectionSeparator, userContext, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _ from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, ListRenderItem, StyleSheet, View } from "react-native"

import { ItemList } from "../../common/List"
import { Todo } from "../../common/Todo"
import { collections } from "../../common/types"
import ListItem from "../../components/ListItem"
import { KINE_TODO } from "../../features/config/Constants"
import currentDateMs from "../../features/hooks/useDateMock"
import { HomeNS } from "../../features/i18n/constants"
import { AppUserData } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import HomeSectionHeader from "./HomeSectionHeader"

interface HomeTodoViewProps {
  type: "phaseTodos" | "phaseNotTodos"
}

export const renderSeparator = () => <SectionSeparator />

const keyExtractor = (item: Todo) => item.id

const HomeTodoView: React.FC<HomeTodoViewProps> = ({ type }) => {
  const { t } = useTranslation(HomeNS)
  const {
    dimensions: { spacing },
    colors: {
      black: { separator },
      white: { highEmphasis: white },
    },
  } = useTheme()
  const { phaseTodos, phaseNotTodos } = useContext(programContext)
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const isKineRequestedOrSelected =
    userData?.kine || (userData?.practiciansRequested && userData?.practiciansRequested.length > 0)
  const [kineTodo] = useDocumentData<Todo>(
    KINE_TODO && !isKineRequestedOrSelected
      ? ((firestore()
          .collection(collections.TODO)
          .doc(KINE_TODO) as unknown) as firebase.firestore.DocumentReference<Todo>)
      : null,
  )
  const completeTodos = useMemo(() => {
    if (type === "phaseTodos") {
      return kineTodo ? [kineTodo, ...(phaseTodos ?? [])] : phaseTodos
    }
    if (type === "phaseNotTodos") {
      return phaseNotTodos
    }
    return []
  }, [type, kineTodo, phaseTodos, phaseNotTodos])
  const currentDate = dayjs(currentDateMs()).endOf("day").toDate()
  const programStartDate = dayjs(userData?.programStart).toDate()
  const isProgToCome = programStartDate && currentDate <= programStartDate
  const isDone = _.every(completeTodos, todo => userData?.doneTasks?.[todo.id])
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )
  const isEmptyTodo = _.isEmpty(completeTodos)

  const todosWithoutProms = completeTodos?.filter(
    todo => !todo.url?.startsWith("https://doctup.page.link"),
  )

  const s = useMemo(
    () => ({
      view: [
        {
          backgroundColor: white,
        },
      ],
      item: {
        marginVertical: spacing,
      },
      separator: [
        styles.separator,
        {
          backgroundColor: separator,
        },
      ],
      body: [styles.body, { padding: spacing }],
    }),
    [separator, spacing, white],
  )

  const renderItem: ListRenderItem<ItemList> = useCallback(
    ({ item }) => (_.isUndefined(item) ? null : <ListItem {...{ item, type }} />),
    [type],
  )

  const getHeaderTitle = () => (type === "phaseTodos" ? t("todo") : t("notTodo"))

  const getEmptyMessage = () => {
    if (isProgToCome && isOrthoOrImplant) {
      return type === "phaseTodos" ? t("todoToCome") : null
    }
    if (isDone) {
      return type === "phaseTodos" ? t("todoEnd") : null
    }
    return null
  }

  return (
    <View>
      {isEmptyTodo && !isProgToCome && !isDone ? null : (
        <HomeSectionHeader title={getHeaderTitle()} />
      )}
      {getEmptyMessage() ? (
        <Body2 emphasis="high" style={s.body}>
          {getEmptyMessage()}
        </Body2>
      ) : isEmptyTodo ? null : (
        <FlatList
          style={s.view}
          {...{ keyExtractor, renderItem }}
          data={userData?.displayProms !== false ? completeTodos : todosWithoutProms}
          ListFooterComponent={renderSeparator}
          ListHeaderComponent={renderSeparator}
          ItemSeparatorComponent={renderSeparator}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  separator: {
    height: 1,
  },
  body: {
    alignSelf: "center",
  },
})

export default HomeTodoView
