import notifee from "@notifee/react-native"
import {
  Button,
  ButtonType,
  IRowItemProps,
  ISectionHeader,
  ParametersScreen,
  SectionSeparator,
  Subtitle1,
  useAlert,
  useTabLand,
  useTheme,
  useUser,
  VectorIcon,
} from "capsule"
import parsePhoneNumber from "libphonenumber-js"
import _ from "lodash"
import React, { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, SectionListData, StyleSheet, View } from "react-native"

import { config, version } from "../../../package.json"
import { ICON_SIZE, INPUT_HEIGHT, urls } from "../../features/config/Constants"
import { PatientsListNS, SettingsNS } from "../../features/i18n/constants"
import { promsContext } from "../../features/Providers/PromsProvider"
import { roleContext } from "../../features/Providers/RoleProvider"
import { leftChild, rightChild } from "./Constants"

const SettingsScreen = () => {
  const {
    fontMaker,
    colors: {
      surface,
      black,
      primary,
      secondary,
      white: { highEmphasis: white },
    },
    typography: { subtitle1, body2 },
    dimensions: { spacing },
  } = useTheme()
  const { setResearchAccepted, setIsSaved } = useContext(roleContext)
  const { cleanForm } = useContext(promsContext)
  const { logout, userData, user } = useUser()
  const { showDialog, setIsValid } = useAlert()
  const isTabLand = useTabLand()

  const userPhoneNumber = useMemo(
    () => parsePhoneNumber(user?.phoneNumber ?? "")?.formatInternational(),
    [user?.phoneNumber],
  )

  const { t } = useTranslation()

  const s = useMemo(
    () => ({
      buttonLabel: {
        color: primary,
      },
      button: [
        styles.button,
        {
          marginTop: spacing,
          backgroundColor: surface.background,
        },
      ],
      version: {
        typography: body2,
      },
      rightChildText: {
        marginRight: spacing / 2,
        color: black.mediumEmphasis,
      },
      dialogTextColor: {
        color: black.mediumEmphasis,
      },
      separator: {
        paddingVertical: spacing,
      },
      padding: {
        paddingVertical: spacing,
      },
      circle: [
        styles.circle,
        {
          marginRight: spacing,
          backgroundColor: secondary,
        },
      ],
      badgeText: [
        fontMaker({ weight: "Bold" }),
        styles.badgeText,
        {
          color: white,
        },
      ],
    }),
    [
      black.mediumEmphasis,
      body2,
      primary,
      spacing,
      surface.background,
      fontMaker,
      secondary,
      white,
    ],
  )

  const onLogoutPress = useCallback(() => {
    setIsValid?.(true)
    showDialog({
      type: "button",
      title: t(`${SettingsNS}:logout`),
      message: t(`${SettingsNS}:disconnect`),
      messageStyle: s.dialogTextColor,
      positive: {
        buttonStyle: styles.dialogRightButton,
        onPress: () => {
          cleanForm()
          setIsSaved?.(false)
          setResearchAccepted?.(true)
          if (userData?.role === "patient") {
            // noinspection JSIgnoredPromiseFromCall
            notifee.cancelTriggerNotifications()
          }
          logout()
        },
        type: ButtonType.POSITIVE,
        label: t(`${SettingsNS}:confirm`),
        labelType: "destructive",
      },
      negative: {
        buttonStyle: styles.dialogLeftButton,
        type: ButtonType.NEGATIVE,
        textStyle: s.dialogTextColor,
      },
    })
  }, [
    userData?.role,
    setIsValid,
    showDialog,
    t,
    s.dialogTextColor,
    cleanForm,
    setIsSaved,
    setResearchAccepted,
    logout,
  ])

  const renderRightChild = useCallback(
    (text: string | undefined, showIcon: boolean, badge?: number | undefined) => (
      <View style={styles.rightChildContainer}>
        {text ? <Subtitle1 style={s.rightChildText}>{text}</Subtitle1> : null}
        {badge ? (
          <View style={[s.circle]}>
            <Subtitle1 style={s.badgeText}>{badge}</Subtitle1>
          </View>
        ) : null}
        {showIcon ? (
          <VectorIcon
            name="chevron-right"
            category="MaterialIcons"
            color={black.mediumEmphasis}
            size={ICON_SIZE}
          />
        ) : null}
      </View>
    ),
    [s.rightChildText, black.mediumEmphasis, s.badgeText, s.circle],
  )

  const isPatient: boolean = useMemo(() => userData?.role === "patient", [userData?.role])
  const isKine: boolean = useMemo(() => userData?.role === "kine", [userData?.role])
  const isOnco: boolean = useMemo(() => userData?.specialty === "onco", [userData?.specialty])
  const patientsRequests = useMemo(
    () => (isKine && userData?.patientsRequests ? userData?.patientsRequests.length : undefined),
    [userData?.patientsRequests, isKine],
  )
  const editionItems = useMemo(
    () =>
      _.filter(
        [
          {
            name: t(`${SettingsNS}:profile`),
            iconName: "person",
            rightChild: renderRightChild(
              t("name", {
                ns: PatientsListNS,
                lastName: userData?.lastName,
                firstName: userData?.firstName,
                interpolation: { escapeValue: false },
              }),
              true,
            ),
            route: isPatient ? "ProfileEdition" : "PractitionerProfileEdition",
          },
          {
            name: t(`${SettingsNS}:surgeonPractice`),
            iconName: "people",
            route: "SurgeonPracticeEdition",
            hidden: userData?.role !== "surgeon",
          },
          {
            name: t(`${SettingsNS}:medicalInfo`),
            iconName: "info",
            route: isOnco ? "OncoDataEdition" : "MedicalInfos",
            hidden: !isPatient,
          },
          {
            name: t(`${SettingsNS}:parameters`),
            iconName: "settings",
            route: "ParametersEdition",
            hidden: userData?.role !== "surgeon",
          },
          {
            name: t(`${SettingsNS}:alert`),
            iconName: "notifications",
            route: "AlertEdition",
            hidden: !isPatient,
          },
          {
            name: t(`${SettingsNS}:code`),
            iconName: "code",
            hidden: !isPatient,
            rightChild: renderRightChild(userData?.code, false),
          },
          {
            name: t(`${SettingsNS}:phoneNumber`),
            iconName: "phone",
            rightChild: renderRightChild(userPhoneNumber, true),
            route: "PhoneEdition",
          },
          {
            name: t(`${SettingsNS}:language`),
            iconName: "language",
            route: "LanguageEdition",
            hidden: !(userData?.specialty === "implant" || userData?.isTestUser),
          },
        ],
        item => !item?.hidden,
      ),
    [
      isPatient,
      isOnco,
      renderRightChild,
      t,
      userData?.code,
      userData?.firstName,
      userData?.lastName,
      userData?.role,
      userData?.specialty,
      userPhoneNumber,
      userData?.isTestUser,
    ],
  )

  const linkItems = useMemo(
    () => [
      isKine
        ? {
            name: t(`${SettingsNS}:KineDataScreen`),
            iconName: "room",
            route: "KineDataScreen",
          }
        : null,
      isKine
        ? {
            name: t(`${SettingsNS}:PatientsRequests`),
            iconName: "switch-account",
            route: "PatientsRequestsScreen",
            rightChild: renderRightChild(undefined, true, patientsRequests),
          }
        : null,
      !isPatient
        ? {
            name: t(`${SettingsNS}:automatic_review`),
            iconName: "star-rate",
            route: "ReviewUrl",
          }
        : null,
      {
        name: t(`${SettingsNS}:support`),
        iconName: "email",
        url: urls.CUSTOMER_SUPPORT,
      },
      {
        name: t("common:parameters.help"),
        iconName: "help",
        url: urls.HELP,
      },
      {
        name: t(`${SettingsNS}:deleteAccount`),
        iconName: "person-remove",
        route: "DeleteAccount",
      },
      {
        name: t("common:parameters.termsOfUse"),
        iconName: "check",
        url: urls.TERMS_OF_USE,
      },
      {
        name: t("common:parameters.privacy"),
        iconName: "check",
        url: urls.PRIVACY,
      },
      isPatient
        ? {
            name: t(`${SettingsNS}:research`),
            iconName: "check",
            route: "Research",
          }
        : null,
    ],
    [isPatient, t, isKine, patientsRequests, renderRightChild],
  )

  const common = useCallback(
    item => ({
      textStyle: subtitle1,
      color: black.highEmphasis,
      name: item?.name,
      rowStyle: styles.row,
      rightChild: item?.rightChild ?? rightChild,
      leftChild: item?.leftChild ?? {
        ...leftChild,
        name: item?.iconName,
        color: black.highEmphasis,
      },
    }),
    [black.highEmphasis, subtitle1],
  )

  const webSeparator = useMemo(
    () => ({
      data: [
        {
          name: "webSeparator",
          render: () => (isTabLand && Platform.OS === "web" ? <SectionSeparator /> : null),
        },
      ],
    }),
    [isTabLand],
  )

  const settingsItems: Array<SectionListData<IRowItemProps, ISectionHeader>> = useMemo(
    () => [
      {
        data: _.map(editionItems, item => ({
          ...common(item),
          route: item?.route,
        })),
        shadows: 1,
        title: "",
      },
      webSeparator,
      {
        data: [
          {
            name: "separator",
            render: () => <View style={s.separator} />,
          },
        ],
        title: "",
      },
      {
        data: _.map(_.compact(linkItems), item => ({
          ...common(item),
          url: item?.url,
          route: item?.route,
        })),
        shadows: 1,
        title: "",
      },
      webSeparator,
    ],
    [common, editionItems, linkItems, s.separator, webSeparator],
  )

  const footerComponent = useMemo(
    () => (
      <Button style={s.button} labelStyle={s.buttonLabel} mode="outlined" onPress={onLogoutPress}>
        {t("common:button.logout")}
      </Button>
    ),
    [onLogoutPress, s.button, s.buttonLabel, t],
  )

  return (
    <>
      <ParametersScreen
        listKey="settingList"
        paddingStyle={s.padding}
        showSeparator
        {...{ footerComponent }}
        sections={settingsItems}
        color={black.mediumEmphasis}
        version={`${version}-${config.build ?? "0"}`}
      />
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    height: INPUT_HEIGHT,
  },
  button: {
    width: "100%",
  },
  rightChildContainer: {
    flexDirection: "row",
    justifyContent: "center",
  },
  dialogLeftButton: {
    marginRight: 0,
    flex: 0,
  },
  dialogRightButton: {
    marginLeft: 0,
    flex: 0,
  },
  circle: {
    width: 25,
    height: 25,
    borderRadius: 15,
  },
  badgeText: {
    textAlign: "center",
  },
})

export default SettingsScreen
