import { useEffect, useState } from "react"
import { Platform } from "react-native"
import { Permission, PERMISSIONS, request, RESULTS } from "react-native-permissions"

const useLocationPermission = () => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)

  const requestLocationPermission = async () => {
    const permission: Permission | undefined = Platform.select({
      ios: PERMISSIONS.IOS.LOCATION_WHEN_IN_USE,
      android: PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION,
    })

    if (permission) {
      const result = await request(permission)
      if (result === RESULTS.GRANTED) {
        setHasPermission(true)
      } else {
        setHasPermission(false)
      }
    } else {
      setHasPermission(false)
    }
  }

  useEffect(() => {
    requestLocationPermission()
  }, [])

  return { hasPermission, requestLocationPermission }
}

export default useLocationPermission
