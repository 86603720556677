import { Subtitle1, useTheme, VIDEO_RATIO } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, useWindowDimensions, View } from "react-native"
import { IconButton } from "react-native-paper"

import { ExerciseNS } from "../features/i18n/constants"

interface EmptyVideoProps {
  onReload?: () => void
}

const EmptyVideo = ({ onReload }: EmptyVideoProps) => {
  const {
    dimensions: { spacing },
    colors: { primary },
  } = useTheme()
  const { t } = useTranslation(ExerciseNS)
  const { width } = useWindowDimensions()
  const s = useMemo(
    () => ({
      empty: [
        styles.empty,
        {
          padding: spacing,
          height: width / VIDEO_RATIO,
        },
      ],
    }),
    [spacing, width],
  )

  return (
    <View style={s.empty}>
      <Subtitle1 emphasis="high" style={styles.subtitle}>
        {t("empty")}
      </Subtitle1>
      {onReload && (
        <View style={styles.iconContainer}>
          <IconButton icon="replay" size={96} rippleColor={primary} onPress={onReload} />
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  empty: {
    width: "100%",
    justifyContent: "center",
    marginTop: 16,
  },
  subtitle: {
    textAlign: "center",
  },
  iconContainer: {
    alignItems: "center",
  },
})

export default EmptyVideo
