import { firestore, H6, IUserContext, userContext, useTheme } from "capsule"
import firebase from "firebase"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { Organ } from "../../common/Pathology"
import { collections } from "../../common/types"
import { PractitionerHomeNS } from "../../features/i18n/constants"
import { AppUserData, Practician } from "../../features/models/UserData"
import PracticianItem from "../PracticianListScreen/PracticianItem"

interface IProps {
  role: string
}

const keyExtractor = (item: any) => item.id

const PracticiansRequestedScreen: FC<IProps> = ({ role }) => {
  const { t } = useTranslation()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const [isLoading, setIsLoading] = useState(false)

  const {
    dimensions: { spacing },
    typography: { body1 },
    colors: { primary },
  } = useTheme()
  const s = useMemo(
    () => ({
      listView: {
        flex: 1,
      },
      flatListContent: {
        paddingBottom: spacing * 10,
      },
      noRequestView: [
        styles.noRequestView,
        {
          padding: spacing * 3,
        },
      ],
      noRequestText: {
        lineHeight: body1.lineHeight,
      },
    }),
    [spacing, body1.lineHeight],
  )
  const [organs] = useCollectionData<Organ>(
    (firestore().collection(
      collections.ORGANS,
    ) as unknown) as firebase.firestore.Query<AppUserData>,
  )
  const filteredOrgans = useMemo(
    () =>
      organs
        ? organs.map(organ => ({
            id: organ.id,
            i18n: organ.i18n,
          }))
        : [],
    [organs],
  )
  const query = useMemo(() => {
    if (userData?.practiciansRequested && userData.practiciansRequested.length > 0) {
      return (firestore()
        .collection(collections.LOGIN)
        .where(
          firestore.FieldPath.documentId(),
          "in",
          userData.practiciansRequested,
        ) as unknown) as firebase.firestore.Query<Practician>
    }
    return null
  }, [userData])
  const [practicianDocs] = useCollectionData<Practician>(query, { idField: "id" })

  const renderContent = useCallback(
    ({ item }: any) => (
      <PracticianItem item={item} role={role} organs={filteredOrgans} setIsLoading={setIsLoading} />
    ),
    [role, filteredOrgans],
  )

  if (!practicianDocs || practicianDocs.length === 0) {
    return (
      <View style={s.noRequestView}>
        <H6 style={s.noRequestText}>{t(`${PractitionerHomeNS}:noPracticianRequests`)}</H6>
      </View>
    )
  }
  return (
    <View style={s.listView}>
      {isLoading ? (
        <View style={styles.noRequestView}>
          <ActivityIndicator color={primary} size="large" />
        </View>
      ) : (
        <FlatList
          key="practicianList"
          data={practicianDocs}
          renderItem={renderContent}
          keyExtractor={keyExtractor}
          contentContainerStyle={s.flatListContent}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  noRequestView: { flex: 1, justifyContent: "center", alignItems: "center" },
})

export default PracticiansRequestedScreen
