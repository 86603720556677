import {
  Button,
  functions,
  IUserContext,
  logger,
  Subtitle1,
  Subtitle2,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import React, { FC, useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { maxWidth } from "../../capsule/features/Theme/dimensions"
import { collections, OnHandlePatientsRequests } from "../../common/types"
import useItemI18n from "../../features/hooks/useItemI18n"
import { PractitionerHomeNS } from "../../features/i18n/constants"
import { AppUserData, Patient } from "../../features/models/UserData"

interface IProps {
  item: Patient
}

const PatientRequestItem: FC<IProps> = React.memo(({ item }) => {
  const { t } = useTranslation(PractitionerHomeNS)
  const { showDialog, showSnack, onDismiss } = useAlert()
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useContext<IUserContext<AppUserData>>(userContext)
  const {
    fontMaker,
    dimensions: { spacing },
    colors: {
      accent,
      black: { highEmphasis: black },
      white: { highEmphasis: white },
      danger,
      primary,
    },
  } = useTheme()
  const s = useMemo(
    () => ({
      cardView: [
        styles.body,
        {
          padding: spacing,
          marginHorizontal: spacing,
          marginBottom: spacing,
          backgroundColor: accent,
        },
      ],
      nameText: [
        fontMaker({ weight: "Bold" }),
        styles.nameText,
        {
          color: black,
          marginBottom: spacing / 4,
        },
      ],
      bodyText: [
        styles.nameText,
        fontMaker({ weight: "Bold" }),
        {
          color: black,
          marginTop: spacing / 2,
        },
      ],
      filterButton: [
        styles.filterButton,
        {
          margin: spacing,
        },
      ],
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
    }),
    [spacing, black, accent, fontMaker],
  )
  const calculateAge = birthDateString => {
    const birthDate = dayjs(birthDateString)
    const today = dayjs()
    const age = today.diff(birthDate, "year")
    return age
  }
  const pathologyName = useItemI18n(collections.PATHOLOGIES, item?.pathology as string, "name")
  const userAge = calculateAge(item.birthdate)
  const userSurgeryDate = dayjs(item.surgeryDate).format("DD/MM/YYYY")
  const handlePatientRequest = useCallback(
    async (isValidate?: boolean) => {
      setIsLoading(true)
      try {
        if (user?.uid) {
          const params: OnHandlePatientsRequests = {
            practician: user?.uid,
            patient: item.id,
            validate: isValidate,
          }
          await functions().httpsCallable("handlePatientsRequests")(params)
          setIsLoading(false)
          showSnack({
            message: t(isValidate ? `validateFollowModal.success` : `rejectFollowModal.success`, {
              ns: PractitionerHomeNS,
              lastName: item.lastName,
              firstName: item.firstName,
            }),
          })
        }
      } catch (e) {
        logger("Patient request error", e)
        setIsLoading(false)
        showSnack({
          message: isValidate ? t(`practicianListModal.error`) : t(`rejectFollowModal.error`),
        })
      }
    },
    [item.firstName, item.lastName, item.id, showSnack, t, user?.uid],
  )

  const onValidate = useCallback(
    (isValidate?: boolean) => {
      showDialog({
        type: "button",
        style: s.dialog,
        contentContainerStyle: styles.dialogContent,
        title: isValidate ? t(`validateFollowModal.title`) : t(`rejectFollowModal.title`),
        message: t(isValidate ? `validateFollowModal.text` : `rejectFollowModal.text`, {
          ns: PractitionerHomeNS,
          lastName: item.lastName,
          firstName: item.firstName,
        }),
        positive: {
          onPress: () => {
            onDismiss()
            handlePatientRequest(isValidate)
          },
          label: isValidate ? t("validateFollowModal.validate") : t("rejectFollowModal.validate"),
          labelType: "cancel",
        },
        negative: {
          label: isValidate ? t("validateFollowModal.cancel") : t("rejectFollowModal.cancel"),
        },
      })
    },
    [s.dialog, showDialog, t, item.firstName, item.lastName, handlePatientRequest, onDismiss],
  )

  return (
    <View style={s.cardView}>
      {isLoading ? (
        <ActivityIndicator color={primary} size="large" />
      ) : (
        <View>
          <Subtitle1 style={s.nameText}>
            {item.firstName} {item.lastName}
          </Subtitle1>
          <Subtitle2 style={s.bodyText}>
            {t(`${PractitionerHomeNS}:userAge`, {
              ns: PractitionerHomeNS,
              patientAge: userAge,
            })}
          </Subtitle2>
          <Subtitle2 style={s.bodyText}>{pathologyName}</Subtitle2>
          <Subtitle2 style={s.bodyText}>
            {t(`${PractitionerHomeNS}:surgeryDate`)} {userSurgeryDate}
          </Subtitle2>
          {item.surgeonName ? (
            <Subtitle2 style={s.bodyText}>
              {t(`${PractitionerHomeNS}:surgeonName`)} {item.surgeonName}
            </Subtitle2>
          ) : null}
          <View style={styles.buttonView}>
            <Button
              style={s.filterButton}
              contentStyle={{ backgroundColor: white }}
              onPress={() => onValidate(true)}
              labelStyle={{ color: black }}
            >
              {t(`${PractitionerHomeNS}:requestButtons.validate`)}
            </Button>
            <Button
              style={s.filterButton}
              contentStyle={{ backgroundColor: white }}
              labelStyle={{ color: danger }}
              onPress={() => onValidate()}
            >
              {t(`${PractitionerHomeNS}:requestButtons.cancel`)}
            </Button>
          </View>
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  filterButton: {
    borderRadius: 10,
  },
  nameText: { textAlign: "center" },
  dialog: {
    alignItems: "center",
  },
  dialogContent: {
    maxWidth,
    width: "100%",
  },
})

export default PatientRequestItem
