import { firestore, H6, IUserContext, Subtitle1, userContext, useTheme } from "capsule"
import firebase from "firebase"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { ActivityIndicator, FlatList, Platform, StyleSheet, View } from "react-native"

import { collections } from "../../common/types"
import { PractitionerHomeNS } from "../../features/i18n/constants"
import { AppUserData, Patient } from "../../features/models/UserData"
import { maxWidth } from "../../ThemeApp"
import PatientRequestItem from "./PatientRequestItem"

const keyExtractor = (item: any) => item.id

const PatientsRequestsScreen = () => {
  const { t } = useTranslation()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  const {
    fontMaker,
    dimensions: { spacing },
    colors: { primary },
    typography: { body1 },
  } = useTheme()
  const s = useMemo(
    () => ({
      flatListContent: {
        paddingBottom: spacing * 10,
      },
      screenStyle: [
        styles.screenStyle,
        Platform.OS === "web" ? { width: maxWidth, marginTop: spacing } : null,
      ],
      title: [
        fontMaker({ weight: "Bold" }),
        styles.title,
        {
          padding: spacing,
        },
      ],
      noRequestView: [
        styles.noRequestView,
        {
          padding: spacing * 3,
        },
      ],
      noRequestText: {
        lineHeight: body1.lineHeight,
      },
    }),
    [spacing, fontMaker, body1.lineHeight],
  )

  const [patientsRequest, setPatientsRequest] = useState<Patient[] | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const query = useMemo(() => {
    if (userData?.patientsRequests && userData.patientsRequests.length > 0) {
      return (firestore()
        .collection(collections.LOGIN)
        .where(
          firestore.FieldPath.documentId(),
          "in",
          userData.patientsRequests,
        ) as unknown) as firebase.firestore.Query<Patient>
    }
    return null
  }, [userData])

  const [patientsData] = useCollectionData<Patient>(query, { idField: "id" })

  useEffect(() => {
    if (query === null) {
      setIsLoading(false)
      setPatientsRequest([])
    } else if (patientsData !== undefined) {
      setPatientsRequest(patientsData || [])
      setIsLoading(false)
    }
  }, [query, patientsData])

  const renderContent = useCallback(({ item }: any) => <PatientRequestItem item={item} />, [])

  if (isLoading) {
    return (
      <View style={styles.loader}>
        <ActivityIndicator size="large" color={primary} />
      </View>
    )
  }

  if (!patientsRequest || patientsRequest.length === 0) {
    return (
      <View style={s.noRequestView}>
        <H6 style={s.noRequestText}>{t(`${PractitionerHomeNS}:noPatientsRequests`)}</H6>
      </View>
    )
  }

  return (
    <View style={styles.view}>
      {patientsRequest && patientsRequest.length > 0 ? (
        patientsRequest && patientsRequest.length === 1 ? (
          <Subtitle1 style={s.title}>
            {t(`${PractitionerHomeNS}:validateRequest`, {
              ns: PractitionerHomeNS,
              requestsNumber: patientsRequest.length,
            })}
          </Subtitle1>
        ) : (
          <Subtitle1 style={s.title}>
            {t(`${PractitionerHomeNS}:validateRequests`, {
              ns: PractitionerHomeNS,
              requestsNumber: patientsRequest.length,
            })}
          </Subtitle1>
        )
      ) : null}
      <View style={s.screenStyle}>
        <FlatList
          key="practicianList"
          data={patientsRequest}
          renderItem={renderContent}
          keyExtractor={keyExtractor}
          contentContainerStyle={s.flatListContent}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  noRequestView: { flex: 1, justifyContent: "center", alignItems: "center" },
  loader: { flex: 1, justifyContent: "center", alignItems: "center" },
  title: { textAlign: "center" },
  view: {
    alignItems: "center",
    height: "100%",
  },
  screenStyle: {
    flex: 1,
  },
})

export default PatientsRequestsScreen
