import { PathConfigMap } from "@react-navigation/core"
import { createStackNavigator } from "@react-navigation/stack"
import { StackHeaderOptions } from "@react-navigation/stack/lib/typescript/src/types"
import { ThemeProvider, useTheme } from "capsule"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { SpecialtyName } from "../../common/CommonUserData"
import { Phase } from "../../common/Phase"
import { NavigationNS, OperationNS } from "../../features/i18n/constants"
import CodeInfoScreen from "../CodeInfoScreen/CodeInfoScreen"
import OrganScreen from "../OrganScreen/OrganScreen"
import PathologyScreen from "../PathologyScreen/PathologyScreen"
import PracticianListScreen from "../PracticianListScreen/PracticianListScreen"
import PracticiansRequestedScreen from "../PracticiansRequestedScreen/PracticiansRequestedScreen"
import OperationScreen from "./OperationScreen"

export type OperationParamList = {
  LeftSideProtocol: undefined
  Specialty: undefined
  Organ: { specialtyId: string }
  Operation: undefined
  OncoPathology: undefined
  Protocol: { pathology: string; organId: string }
  PhaseResume: { blockPhase: Phase }
  Pathology: { organId: string; specialty: SpecialtyName }
  KineList: undefined
  SurgeonList: undefined
  KineRequestedScreen: undefined
  KineInfoScreen: undefined
  CodeInfoScreen: undefined
}

export const screens: PathConfigMap<OperationParamList> = {
  LeftSideProtocol: "LeftSideProtocol",
  Specialty: "specialty",
  Organ: "organ/:specialtyId",
  Operation: "operation",
  Pathology: "pathology/:organId",
  PhaseResume: "phaseResume",
  KineList: "KineList",
  SurgeonList: "SurgeonList",
  KineRequestedScreen: "KineRequestedScreen",
  KineInfoScreen: "KineInfoScreen",
  CodeInfoScreen: "CodeInfoScreen",
}

const operationNavigator = createStackNavigator<OperationParamList>()

const OperationNavigator = () => {
  const { t } = useTranslation(OperationNS)
  const {
    colors: {
      black: { highEmphasis: black },
    },
    typography: { h6 },
  } = useTheme()

  const customTheme = useMemo(
    () => ({
      colors: {
        overrides: {
          radioButton: {
            checked: black,
            unchecked: black,
          },
        },
      },
    }),
    [black],
  )

  const commonHeaderProps: StackHeaderOptions = useMemo(
    () => ({
      headerBackTitleVisible: false,
      headerTitleStyle: { ...h6, color: black },
    }),
    [black, h6],
  )
  return (
    <operationNavigator.Navigator initialRouteName="Operation">
      <operationNavigator.Screen
        name="Operation"
        component={OperationScreen}
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:Operation:title`),
        }}
      />
      <operationNavigator.Screen
        name="Organ"
        component={OrganScreen}
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:Organ:title`),
        }}
      />
      <operationNavigator.Screen
        name="Pathology"
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:Pathology:title`),
        }}
      >
        {props => (
          <ThemeProvider {...{ customTheme }}>
            <PathologyScreen {...props} />
          </ThemeProvider>
        )}
      </operationNavigator.Screen>
      <operationNavigator.Screen
        name="KineList"
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:PracticianList:kine`),
        }}
      >
        {() => <PracticianListScreen role="kine" />}
      </operationNavigator.Screen>
      <operationNavigator.Screen
        name="SurgeonList"
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:PracticianList:surgeon`),
        }}
      >
        {() => <PracticianListScreen role="surgeon" />}
      </operationNavigator.Screen>
      <operationNavigator.Screen
        name="KineRequestedScreen"
        options={{
          ...commonHeaderProps,
          title: t(`${NavigationNS}:PracticiansRequestedList:title`),
        }}
      >
        {() => <PracticiansRequestedScreen role="kine" />}
      </operationNavigator.Screen>
      <operationNavigator.Screen
        name="CodeInfoScreen"
        options={{
          title: t(`${NavigationNS}:CodeInfo:title`),
        }}
      >
        {() => <CodeInfoScreen />}
      </operationNavigator.Screen>
      <operationNavigator.Screen
        name="KineInfoScreen"
        options={{
          title: t(`${NavigationNS}:CodeInfo:title`),
        }}
      >
        {() => <CodeInfoScreen isKine />}
      </operationNavigator.Screen>
    </operationNavigator.Navigator>
  )
}

export default OperationNavigator
