import { firestore, ISO_8601_DATE, IUserContext, userContext } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import { SessionId } from "../../common/CommonUserData"
import { Exercise } from "../../common/Phase"
import { collections } from "../../common/types"
import { setMetadata } from "../../services/Analytics/shake"
import { daySinceSurgery } from "../../utils/conversion"
import useSpecialtySystem from "../hooks/useSpecialtySystem"
import { checkExerciseValidation } from "../models/ExerciseFunctions"
import { deleteSessionDocument, updateSession } from "../models/PhaseAndSessionFunctions"
import { FirestoreSession, NextSessionAction, SessionDetails } from "../models/Session"
import { AppUserData } from "../models/UserData"
import { editUserData, updateUserData } from "../models/UserDataFunctions"
import { programContext } from "./ProgramProvider"

type Void = () => void
interface SessionContext {
  index: number
  sessionId?: SessionId
  currentEx?: Exercise
  startSession: Void
  endSession: Void
  startExercise: Void
  endExercise: (afterEval?: boolean, skip?: boolean) => Promise<NextSessionAction>
  goToPreviousExercise: () => void
  cancelSession: Void
  cancelOncoSession?: Void
  displayMiSessionCongratulation: () => boolean
  displayProgramCongratulation: () => boolean
}

interface IProps {
  children: ReactNode
}

const defaultValues: Omit<
  SessionContext,
  | "endExercise"
  | "goToPreviousExercise"
  | "displayMiSessionCongratulation"
  | "displayProgramCongratulation"
> = {
  index: 0,
  sessionId: undefined,
  currentEx: undefined,
  startSession: _.noop,
  endSession: _.noop,
  startExercise: _.noop,
  cancelSession: _.noop,
}

// @ts-ignore
export const sessionContext = createContext<SessionContext>(defaultValues)

const SessionProvider = ({ children }: IProps) => {
  const [index, setIndex] = useState(0) // resume reach unfinished exercise (no end field or missing exercise)
  const { userDocRef, userData } = useContext<IUserContext<AppUserData>>(userContext)
  const {
    currentPhase,
    phases,
    phaseExercises,
    sessions,
    currentSession,
    setCurrentSession,
  } = useContext(programContext)
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )
  const { computeNextSession } = useSpecialtySystem()
  const resumedOnce = useRef(false)
  const sessionDocIdRef = useRef<SessionId>()
  const [currentEx, setCurrentEx] = useState(defaultValues.currentEx)
  const [sessionExercises, setSessionExercises] = useState<Exercise[]>()
  useEffect(() => {
    if (currentSession && phaseExercises) {
      setSessionExercises(phaseExercises)
    }
  }, [currentSession, phaseExercises])
  /** initialize session's exercises and check if unfinished exercise is present */
  useEffect(() => {
    if (sessionExercises) {
      if (currentSession?.docId && !resumedOnce.current) {
        resumedOnce.current = true
        sessionDocIdRef.current = currentSession?.docId
        setIndex(currentSession?.unfinishedExIndex ?? 0)
        setCurrentEx(sessionExercises?.[currentSession?.unfinishedExIndex ?? 0])
        return
      }
      setCurrentEx(sessionExercises?.[index])
    }
  }, [currentSession, index, sessionExercises])

  const startSession = useCallback(() => {
    if (!currentPhase && !currentSession) {
      return
    }
    if (currentSession?.docId) {
      sessionDocIdRef.current = currentSession?.docId
      return
    }
    const start = firestore.Timestamp.now()
    const doc = userDocRef?.collection(collections.SESSIONS).doc()
    const isOnco = userData?.specialty === "onco"
    sessionDocIdRef.current = doc?.id
    const session: Partial<FirestoreSession> = isOnco
      ? {
          start,
          program: userData?.program,
          phase: currentPhase?.id,
          seqInDay: _.parseInt(currentSession?.id ?? "0"),
          daySinceSurgery: daySinceSurgery(userData?.surgeryDate),
        }
      : {
          start,
          phase: currentPhase?.id,
          seqInDay: _.parseInt(currentSession?.id ?? "0"),
          daySinceSurgery: daySinceSurgery(userData?.surgeryDate),
        }
    setCurrentSession(prevState => ({ ...prevState, start } as SessionDetails))
    // noinspection JSIgnoredPromiseFromCall
    updateSession(userDocRef, doc?.id, session)
    updateUserData(userDocRef, { lastSession: start })
    setMetadata("startSession", JSON.stringify(session))
  }, [
    currentPhase,
    currentSession,
    setCurrentSession,
    userData?.program,
    userData?.surgeryDate,
    userDocRef,
    userData?.specialty,
  ])

  const endSession = useCallback(() => {
    updateSession(userDocRef, sessionDocIdRef?.current, {
      end: firestore.Timestamp.now(),
    }).then(() => {
      computeNextSession({
        daySinceSurgery: daySinceSurgery(userData?.surgeryDate),
        seqInDay: _.parseInt(currentSession?.id ?? "0"),
      })
      const start = firestore.Timestamp.now()
      const dateToString = dayjs.unix(start.seconds).format(ISO_8601_DATE)
      const userActivity = userData?.activityTracking

      if (!userActivity || !_.includes(userActivity, dateToString)) {
        const userAtivityDays =
          !userData?.activityDays || userData?.activityDays < 0 ? 1 : userData?.activityDays + 1
        editUserData(userDocRef, {
          activityTracking: firestore.FieldValue.arrayUnion(dateToString) as any,
          activityDays: userAtivityDays,
          firstSessionOfTheDay: true,
        })
      } else {
        editUserData(userDocRef, {
          firstSessionOfTheDay: false,
        })
      }
    })
  }, [
    computeNextSession,
    currentSession?.id,
    userData?.surgeryDate,
    userDocRef,
    userData?.activityTracking,
    userData?.activityDays,
  ])

  const startExercise = useCallback(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateSession(userDocRef, sessionDocIdRef?.current, {
      exercises: {
        [currentEx?.id ?? ""]: {
          start: firestore.Timestamp.now(),
        },
      },
    })
  }, [currentEx?.id, sessionDocIdRef, userDocRef])

  const endExercise = useCallback(
    async (afterEval?: boolean, skip?: boolean) => {
      // noinspection JSIgnoredPromiseFromCall
      if (!afterEval) {
        updateSession(userDocRef, sessionDocIdRef?.current, {
          exercises: {
            [currentEx?.id ?? ""]: {
              end: firestore.Timestamp.now(),
              skip: skip ?? false,
            },
          },
        })
        if (currentSession?.unfinishedExIndex) {
          setCurrentSession(
            pvCurrentSession =>
              ({
                ...pvCurrentSession,
                unfinishedExIndex: undefined,
              } as SessionDetails),
          )
        }

        // onco
        if (currentEx?.dynamicId && !skip) {
          const hasValidation = await checkExerciseValidation({
            surgeryDate: userData?.surgeryDate,
            exercise: currentEx,
            currentPhase,
            phases,
          })
          if (hasValidation) {
            if (index + 1 > (currentSession?.exercises ?? 0) - 1) {
              return NextSessionAction.EVALUATION_AND_FINISH
            }
            return NextSessionAction.EVALUATION
          }
        }
      }

      // reach the last exercise, end session and goBack
      if (index + 1 > (currentSession?.exercises ?? 0) - 1) {
        setIndex(0)
        return NextSessionAction.CLOSE
      }
      setIndex(i => i + 1)
      return NextSessionAction.NEXT
    },
    [
      userDocRef,
      currentEx,
      currentSession?.unfinishedExIndex,
      currentSession?.exercises,
      index,
      setCurrentSession,
      userData?.surgeryDate,
      currentPhase,
      phases,
    ],
  )

  const goToPreviousExercise = useCallback(() => {
    setIndex(oldIndex => oldIndex - 1)
  }, [])

  const cancelSession = useCallback(() => {
    setIndex(0)
    // noinspection JSIgnoredPromiseFromCall
    updateSession(userDocRef, sessionDocIdRef.current, { abandoned: true }).then(() => {
      computeNextSession({
        daySinceSurgery: daySinceSurgery(userData?.surgeryDate),
        seqInDay: _.parseInt(currentSession?.id ?? "0"),
      })
    })
  }, [computeNextSession, currentSession?.id, userData?.surgeryDate, userDocRef])

  const cancelOncoSession = useCallback(() => {
    if (sessionDocIdRef.current) {
      setIndex(0)
      // TODO not delete the session document, keep it
      // and remove it only if a new session is performed by the user
      deleteSessionDocument(userDocRef, sessionDocIdRef.current)
    }
  }, [userDocRef])

  const displayMiSessionCongratulation = useCallback(
    // index start at 0
    () =>
      index + 1 === ((sessionExercises?.length ?? 0) - ((sessionExercises?.length ?? 0) % 2)) / 2,
    [index, sessionExercises?.length],
  )

  /**
   *   */
  const displayProgramCongratulation = useCallback(
    () => (isOrthoOrImplant ? currentSession?.id === sessions?.[sessions?.length - 1]?.id : true),
    [currentSession?.id, sessions, isOrthoOrImplant],
  )

  const contextValue: SessionContext = {
    index,
    sessionId: sessionDocIdRef.current,
    currentEx,
    startSession,
    endSession,
    startExercise,
    endExercise,
    goToPreviousExercise,
    cancelSession,
    cancelOncoSession,
    displayMiSessionCongratulation,
    displayProgramCongratulation,
  }

  return <sessionContext.Provider value={contextValue}>{children}</sessionContext.Provider>
}

export default SessionProvider

export const useSession = (): SessionContext => {
  const context = useContext<SessionContext>(sessionContext)
  if (_.isEmpty(context)) {
    throw new Error("useSession must be used within a SessionProvider")
  }
  return context
}
