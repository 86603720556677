import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  firestore,
  IUserContext,
  logger,
  Subtitle1,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import {
  FlatList,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native"
import { ActivityIndicator, Searchbar } from "react-native-paper"

import { maxWidth } from "../../capsule/features/Theme/dimensions"
import { Organ } from "../../common/Pathology"
import { collections } from "../../common/types"
import useLocationPermission from "../../features/hooks/useLocationPermission"
import { MainNS, PatientNS } from "../../features/i18n/constants"
import {
  filteredPracticiansDocs,
  sortPracticiansByProximity,
} from "../../features/models/PracticianListFunctions"
import { AppUserData, Practician } from "../../features/models/UserData"
import PracticianItem from "./PracticianItem"

interface IProps {
  role: string
  country?: string
}

const keyExtractor = (item: any) => item.id

const PracticianListScreen: FC<IProps> = ({ role, country }) => {
  const { t } = useTranslation()
  const navigation = useNavigation<any>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const [searchInput, setSearchInput] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isItemLoading, setIsItemLoading] = useState(false)
  const [isPostalCodeSelected, setIsPostalCodeSelected] = useState(false)
  const [locationFilteredPracticians, setLocationFilteredPracticians] = useState<Practician[]>([])
  const { hasPermission } = useLocationPermission()
  const practicianRequested = userData?.practiciansRequested
  const {
    fontMaker,
    colors: {
      white: { highEmphasis: white },
      surface: { card },
      accent,
      primary,
    },
    dimensions: { spacing },
    typography: { caption },
  } = useTheme()
  const s = useMemo(
    () => ({
      searchTitle: [
        fontMaker({ weight: "Bold" }),
        styles.searchTitle,
        {
          marginVertical: spacing / 2,
        },
      ],
      searchView: [styles.searchView, { backgroundColor: white, marginBottom: spacing / 2 }],
      searchBar: [styles.searchBar, { marginHorizontal: spacing }],
      input: {
        fontSize: caption.fontSize,
      },
      noPracticianAround: [
        styles.noPracticianAround,
        {
          paddingTop: spacing * 10,
          paddingHorizontal: spacing * 1.5,
        },
      ],
      noPracticianText: {
        paddingVertical: spacing,
      },
      listView: {
        flex: 1,
      },
      flatListContent: {
        paddingBottom: spacing * 10,
      },
      buttonBottomView: [
        styles.buttonBottomView,
        {
          paddingHorizontal: spacing,
          paddingTop: spacing,
          paddingBottom: spacing * 2,
          backgroundColor: card,
        },
      ],
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
      filterButtonView: [
        styles.filterButtonView,
        {
          marginVertical: spacing / 2,
        },
      ],
      filterText: [
        fontMaker({ weight: "Bold" }),
        {
          paddingLeft: spacing / 4,
        },
      ],
      icon: {
        marginLeft: spacing / 2,
      },
      contactLink: [fontMaker({ weight: "Bold" })],
      buttonView: {
        marginTop: spacing,
      },
      body: [
        styles.body,
        {
          marginTop: spacing * 2,
          padding: spacing,
          backgroundColor: accent,
        },
      ],
    }),
    [spacing, caption.fontSize, white, fontMaker, card, accent],
  )
  const [organs] = useCollectionData<Organ>(
    (firestore().collection(
      collections.ORGANS,
    ) as unknown) as firebase.firestore.Query<AppUserData>,
  )
  const filteredOrgans = useMemo(
    () =>
      organs
        ? organs.map(organ => ({
            id: organ.id,
            i18n: organ.i18n,
          }))
        : [],
    [organs],
  )

  const [practicianDocs] = useCollectionData<Practician>(
    (firestore()
      .collection(collections.LOGIN)
      .where("role", "==", role)
      .where("shareContact", "==", true)
      .orderBy("lastName", "asc") as unknown) as firebase.firestore.Query<Practician>,
    { idField: "id" },
  )

  const filteredPracticans = useMemo(
    () =>
      !practicianDocs
        ? undefined
        : filteredPracticiansDocs(practicianDocs, searchInput, isPostalCodeSelected, country),
    [practicianDocs, searchInput, isPostalCodeSelected, country],
  )

  const onFilterChange = (filter: boolean) => {
    setSearchInput("")
    setIsPostalCodeSelected(filter)
  }
  useEffect(() => {
    setIsLoading(true)
    const sortAndSetPracticians = async () => {
      try {
        if (filteredPracticans && filteredPracticans.length > 0 && userData?.medicalTeam) {
          const sortedList = await sortPracticiansByProximity(
            filteredPracticans,
            userData?.medicalTeam,
            searchInput,
            hasPermission,
          )
          setLocationFilteredPracticians(sortedList)
          setIsLoading(false)
        } else {
          setLocationFilteredPracticians([])
          setIsLoading(false)
        }
      } catch (error) {
        logger("Erreur de géolocalisation :", error)
        setLocationFilteredPracticians(filteredPracticans ?? [])
        setIsLoading(false)
      }
    }
    sortAndSetPracticians()
  }, [filteredPracticans, hasPermission, userData?.medicalTeam, searchInput])

  const onPress = useCallback(() => {
    navigation.navigate("KineInfoScreen")
  }, [navigation])

  const renderContent = useCallback(
    ({ item }: any) => (
      <PracticianItem
        item={item}
        role={role}
        organs={filteredOrgans}
        setIsLoading={setIsItemLoading}
      />
    ),
    [role, filteredOrgans, setIsItemLoading],
  )
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <View style={s.listView}>
        {!practicianRequested || practicianRequested.length === 0 ? (
          <View style={s.buttonView}>
            <View style={styles.buttonView}>
              <Button
                style={styles.filterButton}
                labelStyle={{ color: isPostalCodeSelected ? primary : white }}
                contentStyle={{ backgroundColor: isPostalCodeSelected ? accent : primary }}
                onPress={() => onFilterChange(false)}
              >
                {t(`${MainNS}:header.name`)}
              </Button>
              <Button
                style={styles.filterButton}
                labelStyle={{ color: isPostalCodeSelected ? white : primary }}
                contentStyle={{ backgroundColor: isPostalCodeSelected ? primary : accent }}
                onPress={() => onFilterChange(true)}
              >
                {t(`${MainNS}:header.postal_code`)}
              </Button>
            </View>
            <View style={s.searchView}>
              <Searchbar
                inputStyle={s.input}
                style={s.searchBar}
                onChangeText={setSearchInput}
                value={searchInput}
                autoCorrect={false}
                textContentType="none"
                spellCheck={false}
                autoCapitalize="none"
                autoComplete="off"
                keyboardType={isPostalCodeSelected ? "numeric" : "default"}
                placeholder={
                  !isPostalCodeSelected
                    ? t(`${MainNS}:header.searchByName`)
                    : t(`${MainNS}:header.searchByPostalCode`)
                }
              />
            </View>
          </View>
        ) : null}
        {isLoading || isItemLoading ? (
          <View style={styles.noRequestView}>
            <ActivityIndicator color={primary} size="large" />
          </View>
        ) : hasPermission && locationFilteredPracticians.length === 0 ? (
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={s.noPracticianAround}>
              <Subtitle1 style={s.noPracticianText}>
                {t(
                  searchInput !== ""
                    ? `${PatientNS}:noPracticiansFound.${role}`
                    : `${PatientNS}:noPracticiansAround.${role}`,
                )}
              </Subtitle1>
            </View>
          </TouchableWithoutFeedback>
        ) : (
          <FlatList
            key="practicianList"
            data={locationFilteredPracticians}
            renderItem={renderContent}
            keyExtractor={keyExtractor}
            contentContainerStyle={s.flatListContent}
            keyboardShouldPersistTaps="handled"
          />
        )}
        <View style={s.buttonBottomView}>
          <Button mode="text" onPress={() => onPress()}>
            <Body2 style={s.contactLink} color={primary}>
              {t(`${PatientNS}:notInList.${role}.title`)}
            </Body2>
          </Button>
        </View>
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  searchTitle: {
    textAlign: "center",
  },
  noRequestView: { flex: 1, justifyContent: "center", alignItems: "center" },
  noPracticianAround: { flex: 1, alignItems: "center" },

  buttonView: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-around",
  },
  filterButton: {
    width: "40%",
    borderRadius: 8,
    marginBottom: "3%",
  },
  searchView: {
    display: "flex",
    alignItems: "flex-start",
  },
  searchBar: {
    elevation: 0,
    width: "90%",
    height: 44,
    borderRadius: 8,
  },
  buttonBottomView: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  dialog: {},
  dialogContent: {
    maxWidth,
    width: "100%",
  },
  filterButtonView: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  filterTextView: {
    flexDirection: "column",
    alignItems: "center",
  },
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
})

export default PracticianListScreen
