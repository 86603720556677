import notifee, {
  AndroidImportance,
  Notification,
  TimestampTrigger,
  TriggerType,
} from "@notifee/react-native"
import { logger, useTheme, useUser } from "capsule"
import dayjs from "dayjs"
import _ from "lodash"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import crashlytics from "../../services/Analytics/crashlytics"
import { daySinceSurgery, ISO8601_TIME } from "../../utils/conversion"
import { AlertNS } from "../i18n/constants"
import { DayNum, LocalNotification } from "../models/Alert"
import { createTrigger } from "../models/AlertFunctions"
import { getUnfinishedExIndex } from "../models/ExerciseFunctions"
import {
  createDailyPhase,
  getNextPhase,
  getNextSessionSlot,
  isResumedSession,
} from "../models/PhaseAndSessionFunctions"
import { Daily, SessionDetails } from "../models/Session"
import { getLastSession, updateUserData } from "../models/UserDataFunctions"
import { useProgram } from "../Providers/ProgramProvider"
import currentDateMs from "./useDateMock"

export type PreviousSession = { daySinceSurgery: number; seqInDay: number }

interface SpecialtyMethodsAndValues {
  error?: string
  loading: boolean
  initDailyPhase: (params: Daily) => Promise<void>
  computeNextSession: (previousSession?: PreviousSession, newSessions?: SessionDetails[]) => void
  displayEvaluation: (evening: boolean) => boolean
  changeCurrentPhase: () => Promise<void>
  createLocalNotifications: (props: LocalNotification) => Promise<void>
  createRequestNotification: (requestNumber: number) => Promise<void>
}

const useSpecialtySystem = (): SpecialtyMethodsAndValues => {
  const {
    colors: { primary },
  } = useTheme()
  const { t } = useTranslation(AlertNS)
  const { user, userData, userDocRef } = useUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const {
    sessions,
    currentSession,
    lastSession,
    currentPhase,
    setSessions,
    setIsSessionReady,
    setCurrentSession,
    setResumedSession,
    setLastSession,
    setPhaseExercises,
    cleanPhase,
  } = useProgram()
  const isOrthoOrImplant = useMemo(
    () => userData?.specialty === "ortho" || userData?.specialty === "implant",
    [userData?.specialty],
  )

  /** data of the previous session to evaluate the state of the phase (only phaseProvider)  */
  const computeNextSession = useCallback(
    (previousSession?: PreviousSession, newSessions?: SessionDetails[]) => {
      /**
       * Create a simple usage for the moment
       * See if the method is still useful for onco
       * one session. The verification of previous exercise is done inside SessionProvider
       * if previousSession => no more session => undefined
       * */
      if (!isOrthoOrImplant) {
        if (previousSession) {
          setCurrentSession(undefined)
          return
        }
        setIsSessionReady(true)
        setCurrentSession(newSessions?.[0] ?? sessions?.[0])
        return
      }
      // ortho
      const currentSessions = _.isEmpty(newSessions) ? sessions : newSessions
      if (_.isEmpty(currentSessions)) {
        return
      }
      setLastSession({
        seqIndDay: previousSession?.seqInDay,
        daySinceSurgery: previousSession?.daySinceSurgery,
      })
      let concatSessions:
        | Array<SessionDetails | Record<string, never>>
        | undefined = currentSessions
      /** Check remaining sessions if daySinceSurgery is still the same (same day)
       * => perform retrieve next one with the remaining and the currentTime */
      if (previousSession?.daySinceSurgery === daySinceSurgery(userData?.surgeryDate)) {
        const remainingSessions = _.drop(currentSessions, previousSession.seqInDay + 1)
        // reach end of the session list for this current day
        if (_.isEmpty(remainingSessions)) {
          setCurrentSession(undefined)
          return
        }
        // Keep session index by adding empty objects to preserve session order
        concatSessions = _.union(
          _.times(previousSession?.seqInDay + 1, () => ({})),
          remainingSessions,
        )
      }
      // next session undefined => still in the previous slot finished, display the next.
      if (concatSessions !== undefined) {
        const nextSession = getNextSessionSlot(concatSessions)
        setIsSessionReady(
          dayjs().isSameOrAfter(
            dayjs(
              nextSession?.slot ?? currentSessions?.[(previousSession?.seqInDay ?? 0) + 1]?.slot,
              ISO8601_TIME,
            ),
          ),
        )
        setCurrentSession(nextSession ?? currentSessions?.[(previousSession?.seqInDay ?? 0) + 1])
      }
    },
    [
      isOrthoOrImplant,
      sessions,
      setIsSessionReady,
      setCurrentSession,
      setLastSession,
      userData?.surgeryDate,
    ],
  )

  /** Retrieve exercises and create sessions and update currentSession
   *  Retrieve the exercises list with useCollectionData complicates
   *  the actualization and the calcul of sessions. This calcul depends on too many
   *  fields that have a huge impact on the whole system of the reeducation part (session + alerts)
   * */
  const initDailyPhase = useCallback(
    async ({ exercises, phase, sessionDetails, program }: Daily) => {
      try {
        cleanPhase()
        setLastSession(undefined)
        setPhaseExercises(exercises)
        if (_.isEmpty(exercises)) {
          setLastSession(undefined)
          setCurrentSession(undefined)
          setSessions(undefined)
        } else {
          const oldSession = await getLastSession(userDocRef, phase, program)
          setLastSession({
            seqIndDay: oldSession?.data?.seqInDay,
            daySinceSurgery: oldSession?.data?.daySinceSurgery,
          })
          setSessions(sessionDetails)
          if (isResumedSession(oldSession.data, userData?.surgeryDate)) {
            const leftSessionDetails = isOrthoOrImplant
              ? _.find(sessionDetails, ns => _.parseInt(ns.id) === oldSession?.data?.seqInDay)
              : sessionDetails?.[0]
            setResumedSession({
              ...leftSessionDetails,
              unfinishedExIndex: getUnfinishedExIndex(oldSession?.data?.exercises),
              docId: oldSession?.docId,
            } as SessionDetails)
          }
          // ortho many sessions per day, need to know the previous session to display the next one
          // onco one session per day if previous one and it is completed, there is no session
          computeNextSession(
            oldSession.data &&
              oldSession.data.daySinceSurgery === daySinceSurgery(userData?.surgeryDate)
              ? {
                  seqInDay: isOrthoOrImplant ? oldSession.data.seqInDay : 0,
                  daySinceSurgery: oldSession.data.daySinceSurgery,
                }
              : undefined,
            sessionDetails,
          )
        }
      } catch (e) {
        logger("phase journey error", e)
        setError(e)
      } finally {
        setLoading(false)
      }
    },
    [
      isOrthoOrImplant,
      setSessions,
      setCurrentSession,
      setResumedSession,
      setLastSession,
      setPhaseExercises,
      userData?.surgeryDate,
      userDocRef,
      cleanPhase,
      computeNextSession,
    ],
  )

  // TODO: see if common or diff
  // TODO: Eval before and after session
  const displayEvaluation = useCallback(
    (evening: boolean) => {
      if (isOrthoOrImplant) {
        return evening
          ? currentSession?.id === sessions?.[sessions?.length - 1].id
          : _.isEmpty(lastSession) ||
              lastSession?.daySinceSurgery !== daySinceSurgery(userData?.surgeryDate)
      }
      return true
    },
    [currentSession?.id, isOrthoOrImplant, lastSession, sessions, userData?.surgeryDate],
  )

  // Notifications
  const createNotificationContent = useCallback(
    async (isNext?: boolean): Promise<Notification> => {
      const channelId = await notifee.createChannel({
        id: isNext ? "phase" : "session",
        name: t(`channel.${isNext ? "phase" : "session"}`),
      })
      return {
        title: t(`notification.${isNext ? "nextPhase" : "title"}`),
        body: t("notification.body"),
        android: {
          channelId,
          color: primary,
          showTimestamp: true,
          smallIcon: "ic_stat_ic_notification",
          pressAction: {
            id: "default",
            launchActivity: "com.siruplab.app.MainActivity",
          },
        },
      }
    },
    [primary, t],
  )

  // Request Notifications
  const createRequestNotification = useCallback(
    async (requestNumber: number) => {
      try {
        const isMultipleRequests = requestNumber > 1
        const channelId = await notifee.createChannel({
          id: t("patientRequestNotif.channel"),
          name: t(
            isMultipleRequests ? "patientRequestNotif.multipleTitle" : "patientRequestNotif.title",
          ),
          importance: AndroidImportance.HIGH,
        })
        await notifee.displayNotification({
          title: t(
            isMultipleRequests ? "patientRequestNotif.multipleTitle" : "patientRequestNotif.title",
          ),
          body: t(
            isMultipleRequests ? "patientRequestNotif.mutipleText" : "patientRequestNotif.text",
          ),
          android: {
            channelId,
            color: primary,
            showTimestamp: true,
            smallIcon: "ic_stat_ic_notification",
            pressAction: {
              id: "default",
              launchActivity: "com.siruplab.app.MainActivity",
            },
          },
          ios: {
            foregroundPresentationOptions: {
              alert: true,
              badge: true,
              sound: true,
            },
          },
        })
      } catch (e) {
        logger("create request notification error", e)
      }
    },
    [primary, t],
  )

  const warnNextPhase = useCallback(
    async (surgeryDate: Date, endDay: number, startTime: Date, endTime: Date | string) => {
      const endDate = dayjs(surgeryDate).add(endDay, "day")
      await Promise.all(
        _.range(1, 3).map(async current => {
          const dayJsNextPhase = endDate
            .add(current, "day")
            .set("h", startTime?.getHours() ?? 0)
            .set(
              "m",
              typeof endTime !== "string" && endTime.getMinutes() ? endTime.getMinutes() : 0,
            )
            .set("s", 0)
            .toDate()
          try {
            const trigger: TimestampTrigger = {
              timestamp: dayjs(dayJsNextPhase).local().valueOf(),
              type: TriggerType.TIMESTAMP,
            }
            await notifee.createTriggerNotification(await createNotificationContent(true), trigger)
          } catch (e) {
            crashlytics.reportError(
              crashlytics.errorCodes.notifee,
              "Can't schedule notification",
              e,
            )
          }
        }),
      )
    },
    [createNotificationContent],
  )

  const createLocalNotifications = useCallback(
    async ({
      userData: { alertDays, alertStartTime, alertEndTime, surgeryDate },
      phase: { startDay, endDay },
      isEdit,
      sessionDetails,
    }: LocalNotification) => {
      try {
        // No sessions and notifications without start/end times and exercises
        if (alertStartTime && alertEndTime && alertDays) {
          // when changing phase, sessions must be updated
          if (!isEdit) {
            computeNextSession(undefined, sessionDetails)
          }
          // To create notifications, sessions with exercises must exist
          if (_.isEmpty(sessionDetails)) {
            return
          }
          await notifee.cancelTriggerNotifications()
          const notification = await createNotificationContent()
          if (startDay && endDay) {
            /** init all notifications of the current Phase without recurring system */
            await Promise.all(
              _(_.range(startDay, endDay + 1))
                .map(async val => {
                  const notifDate = dayjs(surgeryDate).add(val, "day")
                  if (!alertDays[DayNum[notifDate.weekday()]]) {
                    return
                  }
                  return _.map(sessionDetails, async session => {
                    const hour = dayjs(session.slot, ISO8601_TIME).hour()
                    const minute = dayjs(session.slot, ISO8601_TIME).minute()
                    const trigger = createTrigger(
                      notifDate.set("h", hour).set("m", minute).toDate(),
                    )
                    if (trigger) {
                      await notifee.createTriggerNotification(notification, trigger)
                    }
                  })
                })
                .value(),
            )
          }
          /** prepare next phase notifications after creating or editing current phase notifications */
          await warnNextPhase(surgeryDate, endDay, alertStartTime, alertEndTime)
        }
      } catch (e) {
        logger("create notification error", e)
      }
    },
    [computeNextSession, createNotificationContent, warnNextPhase],
  )

  const changeCurrentPhase = useCallback(async () => {
    if (!currentPhase || !userData) {
      return
    }
    setLoading(true)
    const totalDays = currentPhase.endDay
    const endDate = dayjs(userData.surgeryDate).add(totalDays, "day")
    if (dayjs(currentDateMs()).utc(true).isAfter(endDate, "day")) {
      const nextPhase = await getNextPhase(userData.pathology, userData.surgeryDate)
      if (nextPhase) {
        updateUserData(userDocRef, { phase: nextPhase?.id })
        // avoid calling session's functions if phase has no exercises
        if (userData) {
          await createDailyPhase({
            user,
            phase: nextPhase,
            userData,
            initDailyPhase,
            isEdit: false,
            createLocalNotifications,
          })
        }
      }
    }
    setLoading(false)
  }, [createLocalNotifications, initDailyPhase, userDocRef, currentPhase, userData, user])

  return {
    error,
    loading,
    initDailyPhase,
    computeNextSession,
    displayEvaluation,
    changeCurrentPhase,
    createLocalNotifications,
    createRequestNotification,
  }
}

export default useSpecialtySystem
