import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs"
import { EmptyView, firestore, IUserContext, userContext, useTheme } from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import React, { FC, useContext, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, StyleSheet, View } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"
import { Edge } from "react-native-safe-area-context/lib/typescript/src/SafeArea.types"

import { collections } from "../../common/types"
import ActivityTrackingComponent from "../../components/ActivityTrackingComponent"
import ProtocolEvaluationsView from "../../components/ProtocolEvaluationsView"
import ProtocolExercicesView from "../../components/ProtocolExercicesView"
import ProtocolInfoView from "../../components/ProtocolInfoView"
import { PatientNS } from "../../features/i18n/constants"
import { getFirestoreDate } from "../../features/models/Types"
import { AppUserData, Patient } from "../../features/models/UserData"
import { programContext } from "../../features/Providers/ProgramProvider"
import { IMainParamList } from "../MainScreen/MainNavigator"
import JourneyHeader from "./JourneyHeader"

export type JourneyScreenNavigationProp = BottomTabNavigationProp<IMainParamList, "PatientJourney">

const PatientJourneyScreen: FC = () => {
  const edges: Edge[] = ["left", "right", "top"]
  const { userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const { currentPhase, phaseExercises } = useContext(programContext)
  const { t } = useTranslation(PatientNS)
  const {
    typography: { h6 },
    colors: {
      surface: { background },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()
  const s = useMemo(
    () => ({
      safe: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      scrollView: [
        styles.scrollView,
        {
          backgroundColor: background,
        },
      ],
      indicator: { paddingTop: spacing },
      empty: [h6, { paddingHorizontal: spacing }],
      emptyContainer: { backgroundColor: background },
    }),
    [background, h6, spacing],
  )

  const [patient, loading] = useDocumentData<Patient>(
    userDocRef?.id
      ? ((firestore()
          .collection(collections.LOGIN)
          .doc(userDocRef?.id) as unknown) as firebase.firestore.DocumentReference<Patient>)
      : undefined,
    { idField: "id" },
  )
  const currentDate = dayjs().startOf("day")
  const isProgramEnded =
    patient?.programEnd && currentDate.isAfter(getFirestoreDate(patient.programEnd))
  const FlatListComponents = patient
    ? [
        <JourneyHeader key="JourneyHeader" patient={patient} isProgramEnded={isProgramEnded} />,
        <ProtocolInfoView key="ProtocolInfos" patient={patient} isProgramEnded={isProgramEnded} />,
        <ActivityTrackingComponent
          key="ActivityTracking"
          patient={patient}
          isProgramEnded={isProgramEnded}
          isPatient
        />,
        !isProgramEnded ? (
          <ProtocolExercicesView
            key="ProtocolExercices"
            patient={patient}
            patientId={userDocRef?.id}
            currentPhase={currentPhase}
            currentExos={phaseExercises}
          />
        ) : null,
        <ProtocolEvaluationsView key="ProtocolEvaluations" patient={patient} />,
      ]
    : []

  return (
    <SafeAreaView style={s.safe} {...{ edges }}>
      <View style={s.scrollView}>
        {loading ? (
          <ActivityIndicator style={s.indicator} size="large" color={primary} />
        ) : patient ? (
          <FlatList
            data={FlatListComponents}
            renderItem={({ item }) => <React.Fragment>{item}</React.Fragment>}
          />
        ) : (
          <EmptyView
            titleStyle={s.empty}
            title={t("errors.load_patient")}
            viewStyle={s.emptyContainer}
          />
        )}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  scrollView: {
    height: "100%",
  },
})

export default PatientJourneyScreen
