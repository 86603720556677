import React, { ReactNode } from "react"
import { StyleProp, TextStyle, ViewStyle } from "react-native"
import { Modal, Snackbar as PaperSnackbar } from "react-native-paper"

import { VectorIconProps } from "../../components/VectorIcon"

// eslint-disable-next-line no-shadow
export enum Duration {
  LONG = PaperSnackbar.DURATION_LONG,
  SHORT = PaperSnackbar.DURATION_SHORT,
  MEDIUM = PaperSnackbar.DURATION_MEDIUM,
}

interface SnackAction {
  label: string
  onPress: () => void
}

export interface ISnack {
  message: string
  action?: SnackAction
  style?: StyleProp<ViewStyle>
  duration?: Duration | number
}

export interface ISnackInternal extends ISnack {
  visible: boolean
  onDismiss: () => void
  resolve?: (value: boolean) => void
}

// eslint-disable-next-line no-shadow
export enum ButtonType {
  NEGATIVE = "negative",
  POSITIVE = "positive",
  NEUTRAL = "neutral",
}

export interface AlertButton {
  label?: string
  type?: ButtonType
  loading?: boolean
  onPress?: () => Promise<void> | void
  icon?: VectorIconProps
  textStyle?: StyleProp<TextStyle>
  iconStyle?: StyleProp<ViewStyle>
  labelType?: "cancel" | "destructive" | "confirm"
  buttonStyle?: StyleProp<ViewStyle>
  mode?: "text" | "outlined" | "contained"
}

export type ModalProps = Omit<React.ComponentProps<typeof Modal>, "children">

export interface IDialog {
  title?: string
  message?: string | React.ReactNode
  children?: ReactNode
  positive: AlertButton
  neutral?: AlertButton
  negative?: AlertButton
  titleStyle?: StyleProp<TextStyle>
  messageStyle?: StyleProp<TextStyle>
}

export type IButtonDialog = IDialog & { type: "button" }

export type ITextInputDialog = IDialog & { type: "text" }

export type IDialogInternal = (ITextInputDialog | IButtonDialog) &
  ModalProps & {
    visible: boolean
    onDismiss: () => void
    resolve?: (value: any) => void
  }

export interface DialogResponse {
  button: ButtonType | undefined
}

export type TextInputDialogResponse = DialogResponse & { text: string }
