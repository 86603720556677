import { PathConfigMap } from "@react-navigation/core"
import { useIsFocused } from "@react-navigation/native"
import { ParamListBase } from "@react-navigation/routers"
import { createStackNavigator, StackNavigationOptions } from "@react-navigation/stack"
import { Touchable, TwoPaneScreen, useTabLand, useTheme, VectorIcon } from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform, StyleSheet } from "react-native"
import { IconButton, Portal, Searchbar } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { ID } from "../../common/CommonUserData"
import RdvUrlView from "../../components/RdvUrl"
import { ICON_SIZE } from "../../features/config/Constants"
import { MainNS } from "../../features/i18n/constants"
import { hideHeader } from "../../features/Navigation/NavigationService"
import { faqContext } from "../../features/Providers/FaqProvider"
import AddressScreen from "../AddressScreen/AddressScreen"
import ShareContactScreen from "../AddressScreen/ShareContact"
import CodesScreen from "../CodesScreen/CodesScreen"
import FaqArticleScreen from "../FaqScreen/FaqArticleScreen"
import FaqNavigator from "../FaqScreen/FaqNavigator"
import HomeScreen from "../HomeScreen/HomeScreen"
import KineDataScreen from "../KineDataScreen/KineDataScreen"
import KineSpecialtiesScreen from "../KineSpecialtiesScreen/KineSpecialtiesScreen"
import { OperationParamList } from "../OperationScreen/OperationNavigator"
import OrganScreen from "../OrganScreen/OrganScreen"
import PathologyScreen from "../PathologyScreen/PathologyScreen"
import PatientCalendarScreen from "../PatientJourneyScreen/PatientCalendarScreen"
import PatientGraphScreen from "../PatientJourneyScreen/PatientGraphScreen"
import PatientJourneyScreen from "../PatientJourneyScreen/PatientJourneyScreen"
import PatientProtocolTimeline from "../PatientJourneyScreen/PatientProtocolTimeline"
import PatientScreen from "../PatientScreen/PatientScreen"
import PatientsListNavigator from "../PatientsListScreen/PatientsListNavigator"
import PatientsRequestsScreen from "../PatientsRequestsScreen/PatientsRequestsScreen"
import PhaseResumeScreen from "../PhaseResumeScreen/PhaseResumeScreen"
import ProtocolScreen from "../ProtocolScreen/ProtocolScreen"
import SettingsScreen from "../SettingsScreen/SettingsScreen"
import SpecialitiesScreen from "../SpecialitiesScreen/SpecialityScreen"

export interface IHomeParamList extends ParamListBase {
  Home_Main: undefined
}

export interface IFaqParamList extends ParamListBase {
  Faq_Main: { title: string; faq: true }
  FaqTab_before: { when: string }
  FaqTab_after: { when: string }
  Faq_Article: { id: string }
}

export interface IPatientJourneyParamList extends ParamListBase {
  Patient_Journey_Main: undefined
  Patient_Protocol_Timeline: { id?: ID }
  Patient_Calendar: { id?: ID }
  Patient_Graph: { id?: ID }
}

export interface IAccountParamList extends ParamListBase {
  Account_Main: { title: string; account: true }
}

export interface ICodesParamList extends ParamListBase {
  Codes_Main: undefined
}

export interface IPatientsParamList extends ParamListBase {
  Patients_Main: undefined
  Patient_Profile: { id?: ID }
}

export const homeScreens: PathConfigMap<IHomeParamList> = {
  Home_Main: "*",
}

export const faqScreens: PathConfigMap<IFaqParamList> = {
  Faq_Main: "*",
  Faq_Article: "faq/:id",
}

export const patientJourneyScreens: PathConfigMap<IPatientJourneyParamList> = {
  Patient_Journey_Main: "*",
  Patient_Protocol_Timeline: "timeline/patient/:id",
  Patient_Calendar: "calendar/patient/:id",
  Patient_Graph: "graph/patient/:id",
}

export const accountScreens: PathConfigMap<IAccountParamList> = {
  Account_Main: "*",
}

export const codesScreens: PathConfigMap<ICodesParamList> = {
  Codes_Main: "*",
}

export const protocolsScreens: PathConfigMap<OperationParamList> = {
  Organ: "organ/:specialtyId",
  Pathology: "pathology/:organId",
  Protocol: "Protocol",
}

export const patientsScreens: PathConfigMap<IPatientsParamList> = {
  Patients_Main: "*",
  Patient_Profile: "patient/:id",
}

export const homeStack = createStackNavigator<IHomeParamList>()
export const faqStack = createStackNavigator<IFaqParamList>()
export const patientJourneyStack = createStackNavigator<IPatientJourneyParamList>()
export const accountStack = createStackNavigator<IAccountParamList>()
export const codesStack = createStackNavigator<ICodesParamList>()
export const protocolsStack = createStackNavigator<OperationParamList>()
export const patientsStack = createStackNavigator<IPatientsParamList>()

const useHeaderStyle = () => {
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
    typography: { h6 },
  } = useTheme()
  const isTabLand = useTabLand()
  return useMemo<StackNavigationOptions>(() => {
    const screenOptions: StackNavigationOptions = {
      headerStyle: [
        styles.headerStyle,
        {
          backgroundColor: isTabLand && Platform.OS === "web" ? white : primary,
        },
      ],
      headerTitleStyle: {
        ...h6,
        color: isTabLand && Platform.OS === "web" ? black : white,
      },
      headerBackTitleVisible: false,
      headerTintColor: isTabLand && Platform.OS === "web" ? black : white,
    }
    return screenOptions
  }, [black, h6, isTabLand, primary, white])
}

export const MainHomeTab: FC = () => {
  const {
    colors: {
      primary,
      surface: { appUi },
    },
  } = useTheme()
  const isTabLand = useTabLand()
  const { t } = useTranslation(MainNS)
  return (
    <homeStack.Navigator
      screenOptions={
        isTabLand && Platform.OS === "web"
          ? { headerTintColor: primary, headerStyle: { backgroundColor: appUi } }
          : undefined
      }
    >
      <homeStack.Screen
        name="Home_Main"
        component={HomeScreen}
        options={{
          title: t("header.home"),
          headerShown: false,
          headerBackTitleVisible: false,
        }}
      />
    </homeStack.Navigator>
  )
}

export const MainFaqTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const {
    colors: {
      primary,
      white: { highEmphasis: white },
      black: { mediumEmphasis: black },
    },
    dimensions: { spacing },
    typography: { h6 },
  } = useTheme()
  const isFocused = useIsFocused()

  const s = useMemo(
    () => ({
      searchBar: [styles.searchBar, { marginHorizontal: spacing }],
      headerStyle: [
        styles.headerStyle,
        {
          backgroundColor: primary,
        },
      ],
      headerTitleStyle: {
        ...h6,
        color: white,
      },
      headerTintColor: white,
    }),
    [h6, primary, spacing, white],
  )
  const screenOptions = useHeaderStyle()

  const { searchInput, setSearchInput, setSearchBarVisible, searchBarVisible } = useContext(
    faqContext,
  )
  const handleClosingIconPress = useCallback(() => setSearchBarVisible(false), [
    setSearchBarVisible,
  ])
  const handleOpeningIconPress = useCallback(() => setSearchBarVisible(true), [setSearchBarVisible])

  useEffect(() => {
    if (!isFocused) {
      setSearchBarVisible(false)
    }
  }, [isFocused, setSearchBarVisible])

  const onClearIcon = useCallback(
    () => (_.isEmpty(searchInput) ? handleClosingIconPress() : setSearchInput("")),
    [handleClosingIconPress, searchInput, setSearchInput],
  )

  const clearSearchIcon = useMemo(
    () => () => (
      <Touchable onPress={onClearIcon} style={styles.clearIconContainer}>
        <VectorIcon category="MaterialIcons" name="cancel" size={ICON_SIZE} color={black} />
      </Touchable>
    ),
    [black, onClearIcon],
  )

  const handleSearch = useCallback(
    () =>
      searchBarVisible ? (
        <Portal>
          <SafeAreaView>
            <Searchbar
              style={s.searchBar}
              onChangeText={setSearchInput}
              value={searchInput ?? ""}
              placeholder={t(`header.placeholder`)}
              onIconPress={handleClosingIconPress}
              clearIcon={clearSearchIcon}
            />
          </SafeAreaView>
        </Portal>
      ) : (
        <IconButton
          onPress={handleOpeningIconPress}
          icon="magnify"
          iconColor={white}
          size={ICON_SIZE}
        />
      ),
    [
      searchBarVisible,
      s.searchBar,
      setSearchInput,
      searchInput,
      t,
      handleClosingIconPress,
      clearSearchIcon,
      handleOpeningIconPress,
      white,
    ],
  )

  return (
    <faqStack.Navigator screenOptions={screenOptions}>
      <faqStack.Screen
        name="Faq_Main"
        component={FaqNavigator}
        options={{
          title: t(`header.faq`),
          headerRight: handleSearch,
        }}
      />
      <faqStack.Screen
        name="Faq_Article"
        component={FaqArticleScreen}
        options={{
          title: "",
        }}
      />
    </faqStack.Navigator>
  )
}

export const MainCodesTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const screenOptions = useHeaderStyle()
  return (
    <codesStack.Navigator screenOptions={screenOptions}>
      <codesStack.Screen
        name="Codes_Main"
        component={CodesScreen}
        options={{
          title: t(`header.codes`),
        }}
      />
    </codesStack.Navigator>
  )
}

export const MainPatientJourneyTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const screenOptions = useHeaderStyle()
  return (
    <patientJourneyStack.Navigator>
      <patientJourneyStack.Screen
        name="Patient_Journey_Main"
        component={PatientJourneyScreen}
        options={{
          ...hideHeader,
        }}
      />
      <patientJourneyStack.Screen
        name="Patient_Protocol_Timeline"
        component={PatientProtocolTimeline}
        options={{
          title: t(`header.timelineProtocol`),
          headerStyle: screenOptions.headerStyle,
          headerTintColor: screenOptions.headerTintColor,
          headerBackTitleVisible: false,
          headerTitleStyle: screenOptions.headerTitleStyle,
        }}
      />
      <patientJourneyStack.Screen
        name="Patient_Calendar"
        component={PatientCalendarScreen}
        options={{
          title: t(`header.calendar`),
          headerStyle: screenOptions.headerStyle,
          headerTintColor: screenOptions.headerTintColor,
          headerBackTitleVisible: false,
          headerTitleStyle: screenOptions.headerTitleStyle,
        }}
      />
      <patientJourneyStack.Screen
        name="Patient_Graph"
        component={PatientGraphScreen}
        options={{
          title: t(`header.graph`),
          headerStyle: screenOptions.headerStyle,
          headerTintColor: screenOptions.headerTintColor,
          headerBackTitleVisible: false,
          headerTitleStyle: screenOptions.headerTitleStyle,
        }}
      />
    </patientJourneyStack.Navigator>
  )
}

export const MainProtocolsTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const screenOptions = useHeaderStyle()

  return (
    <TwoPaneScreen>
      <protocolsStack.Navigator>
        <protocolsStack.Screen name="LeftSideProtocol" options={hideHeader}>
          {() => (
            <protocolsStack.Navigator screenOptions={screenOptions}>
              <protocolsStack.Screen
                name="Specialty"
                component={SpecialitiesScreen}
                options={{
                  title: t(`header.specialties`),
                }}
              />
              <protocolsStack.Screen
                name="Organ"
                component={OrganScreen}
                options={{
                  title: t(`header.organs`),
                }}
              />
              <protocolsStack.Screen
                name="Pathology"
                options={{
                  title: t(`header.pathologies`),
                }}
              >
                {props => <PathologyScreen {...props} />}
              </protocolsStack.Screen>
              <protocolsStack.Screen
                name="Protocol"
                component={ProtocolScreen}
                options={{
                  title: t(`header.protocols`),
                }}
              />
              <protocolsStack.Screen
                name="PhaseResume"
                options={{
                  title: t(`header.phaseResume`),
                }}
              >
                {props => <PhaseResumeScreen {...props} />}
              </protocolsStack.Screen>
            </protocolsStack.Navigator>
          )}
        </protocolsStack.Screen>
      </protocolsStack.Navigator>
    </TwoPaneScreen>
  )
}

export const MainPatientsTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const screenOptions = useHeaderStyle()
  return (
    <TwoPaneScreen>
      <patientsStack.Navigator {...{ screenOptions }}>
        <patientsStack.Screen
          name="Patients_Main"
          component={PatientsListNavigator}
          options={{
            title: t(`header.patients`),
          }}
        />
        <patientsStack.Screen
          name="Patient_Profile"
          component={PatientScreen}
          options={{
            title: t("header.patient"),
            headerTitle: () => undefined,
          }}
        />
        <patientsStack.Screen
          name="Patient_Calendar"
          component={PatientCalendarScreen}
          options={{
            title: t(`header.calendar`),
            headerStyle: screenOptions.headerStyle,
            headerTintColor: screenOptions.headerTintColor,
            headerBackTitleVisible: false,
            headerTitleStyle: screenOptions.headerTitleStyle,
          }}
        />
      </patientsStack.Navigator>
      <PatientScreen />
    </TwoPaneScreen>
  )
}

export const MainAccountTab: FC = () => {
  const { t } = useTranslation(MainNS)
  const screenOptions = useHeaderStyle()
  return (
    <accountStack.Navigator screenOptions={screenOptions}>
      <accountStack.Screen
        name="Account_Main"
        component={SettingsScreen}
        initialParams={{ title: "Account" }}
        options={{
          title: t("common:parameters.account"),
        }}
      />
      <accountStack.Screen
        name="KineDataScreen"
        options={{
          title: t("common:KineDataScreen"),
        }}
        component={KineDataScreen}
      />
      <accountStack.Screen
        name="PatientsRequestsScreen"
        options={{
          title: t("common:PatientsRequests"),
        }}
        component={PatientsRequestsScreen}
      />
      <accountStack.Screen
        name="ShareContactEdition"
        options={{
          title: t("common:ShareContactEdition"),
        }}
      >
        {() => <ShareContactScreen isEdit />}
      </accountStack.Screen>
      <accountStack.Screen
        name="KineSpecialtiesEdition"
        options={{
          title: t("common:KineSpecialtiesEdition"),
        }}
      >
        {() => <KineSpecialtiesScreen isEdit />}
      </accountStack.Screen>

      <accountStack.Screen
        name="AddressEdition"
        options={{
          title: t("common:AddressEdition"),
        }}
      >
        {() => <AddressScreen isEdit />}
      </accountStack.Screen>
      <accountStack.Screen
        options={{
          title: t("common:RdvUrl"),
        }}
        name="RdvUrl"
      >
        {() => <RdvUrlView isEdit />}
      </accountStack.Screen>
    </accountStack.Navigator>
  )
}

const styles = StyleSheet.create({
  searchBar: {
    elevation: 0,
    width: "auto",
    height: 44,
    borderRadius: 8,
  },
  headerStyle: {
    elevation: 0,
    shadowOpacity: 0,
    borderBottomWidth: 0,
  },
  clearIconContainer: {
    justifyContent: "center",
  },
})
