import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  firestore,
  generateShadow,
  IUserContext,
  openUrl,
  ParametersScreen,
  userContext,
  useTheme,
} from "capsule"
import firebase from "firebase"
import React, { FC, useCallback, useContext, useEffect, useMemo } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { Image, ImageSourcePropType, StyleSheet, View, ViewStyle } from "react-native"

import { images } from "../../assets/images"
import { collections } from "../../common/types"
import { INPUT_HEIGHT } from "../../features/config/Constants"
import useLocationPermission from "../../features/hooks/useLocationPermission"
import usePermissions from "../../features/hooks/usePermissions"
import { OperationNS } from "../../features/i18n/constants"
import { AppUserData, Practician } from "../../features/models/UserData"
import RdvButton from "./RdvButton"

interface IProps {
  isEdit?: boolean
}

const MedicalTeamScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    fontMaker,
    colors: {
      accent,
      white: { highEmphasis: white },
      black: { mediumEmphasis: black },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()
  usePermissions(isEdit)
  const { requestLocationPermission } = useLocationPermission()

  useEffect(() => {
    requestLocationPermission()
  }, [requestLocationPermission])
  const { t } = useTranslation(OperationNS)
  const navigation = useNavigation<any>()

  const { userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const query = useMemo(() => {
    if (userData?.practiciansRequested && userData.practiciansRequested.length > 0) {
      return (firestore()
        .collection(collections.LOGIN)
        .where(
          firestore.FieldPath.documentId(),
          "in",
          userData.practiciansRequested,
        ) as unknown) as firebase.firestore.Query<Practician>
    }
    return null
  }, [userData])
  const [practicianDocs] = useCollectionData<Practician>(query, { idField: "id" })
  const requestedKine = practicianDocs?.find(tm => tm.role === "kine")
  const code = userData?.code || t("missing")
  const isPracticianRequested =
    userData?.practiciansRequested && userData?.practiciansRequested.length > 0
  const practicianRequestedText = isPracticianRequested ? t("requestSent") : t("missing")
  const teamArray = userData?.medicalTeamInfo
    ? Object.entries(userData?.medicalTeamInfo).map(([id, { firstName, lastName, role }]) => ({
        id,
        firstName,
        lastName,
        role,
      }))
    : []
  const surgeonTeam = teamArray.filter(tm => tm.role === "surgeon")
  const gpTeam = teamArray.filter(tm => tm.role === "gp")
  const kineTeam = teamArray.filter(tm => tm.role === "kine")

  const s = useMemo(
    () => ({
      row: [
        styles.row,
        {
          paddingVertical: spacing,
          paddingLeft: spacing * 2,
        },
      ],
      rowSubitem: [
        styles.row,
        styles.shadow,
        {
          paddingLeft: spacing * 2,
          marginTop: spacing / 2,
        },
      ],
      rowInfo: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
          marginBottom: spacing / 2,
        },
      ],
      rowKine: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
          backgroundColor: white,
        },
      ],
      body: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      codeView: [fontMaker({ weight: "Bold" }), styles.codeView],
      icon: {
        tintColor: black,
      },
      buttonContent: [
        styles.buttonContent,
        {
          marginTop: spacing / 2,
        },
      ],
      contactLink: [fontMaker({ weight: "Bold" })],
    }),
    [accent, black, spacing, white, fontMaker],
  )

  const onPress = useCallback(async () => {
    const subject = t(`${OperationNS}:editMedicalTeamTitle`)
    const body = t(`${OperationNS}:editMedicalTeamText`, {
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      id: userDocRef?.id,
    })
    // noinspection ES6MissingAwait
    openUrl(
      `mailto:contact@doctup.fr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body,
      )}`,
    )
    return
  }, [t, userData?.firstName, userData?.lastName, userDocRef?.id])

  const renderKineSection = useMemo(
    () => ({
      shadows: 1,
      data: [
        {
          render: () => (
            <>
              {!requestedKine ? (
                <View style={s.rowInfo}>
                  <View style={s.body}>
                    <Body2>{t("findKine.text")}</Body2>
                    <Button
                      mode="text"
                      labelStyle={styles.buttonLabel}
                      onPress={() => navigation.navigate("KineList")}
                      contentStyle={s.buttonContent}
                    >
                      <Body2 style={s.contactLink} color={primary}>
                        {t("findKine.button")}
                      </Body2>
                    </Button>
                  </View>
                </View>
              ) : (
                <View style={s.rowInfo}>
                  <View style={s.body}>
                    <Body2>
                      {t("seeRequests.text", {
                        ns: OperationNS,
                        lastName: requestedKine?.lastName,
                        firstName: requestedKine?.firstName,
                      })}
                    </Body2>
                    <Button
                      mode="text"
                      labelStyle={styles.buttonLabel}
                      onPress={() => navigation.navigate("KineRequestedScreen")}
                      contentStyle={s.buttonContent}
                    >
                      <Body2 style={s.contactLink} color={primary}>
                        {t("seeRequests.button")}
                      </Body2>
                    </Button>
                  </View>
                </View>
              )}
            </>
          ),
        },
      ],
      title: "",
    }),
    [s, t, primary, navigation, requestedKine],
  )

  const sections = useMemo(() => {
    const surgeonSections =
      surgeonTeam.length === 0
        ? [
            {
              shadows: 1,
              data: [
                {
                  rowStyle: s.row,
                  name: t("missing"),
                  labelStyle: styles.label,
                  label: t("label.surgeon"),
                  leftChild: (
                    <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                  ),
                },
              ],
              title: "",
            },
          ]
        : surgeonTeam.map(surgeon => ({
            shadows: 1,
            data: [
              {
                rowStyle: s.row,
                name: surgeon.firstName + " " + surgeon.lastName,
                labelStyle: styles.label,
                label: t("label.surgeon"),
                leftChild: (
                  <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                ),
              },
              {
                render: () => <RdvButton id={surgeon.id} />,
              },
            ],
            title: "",
          }))

    const kineSections =
      kineTeam.length === 0
        ? [
            {
              shadows: 1,
              data: [
                {
                  rowStyle: s.rowSubitem,
                  name: practicianRequestedText,
                  labelStyle: styles.label,
                  label: t("label.kine"),
                  leftChild: (
                    <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                  ),
                },
              ],
              title: "",
            },
          ]
        : kineTeam.map(kine => ({
            shadows: 1,
            data: [
              {
                rowStyle: s.rowSubitem,
                name: kine.firstName + " " + kine.lastName,
                labelStyle: styles.label,
                label: t("label.kine"),
                leftChild: (
                  <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                ),
              },
              {
                render: () => <RdvButton id={kine.id} />,
              },
            ],
            title: "",
          }))

    const gpSections =
      gpTeam.length === 0
        ? [
            {
              shadows: 1,
              data: [
                {
                  rowStyle: s.rowSubitem,
                  name: t("missing"),
                  labelStyle: styles.label,
                  label: t("label.gp"),
                  leftChild: (
                    <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                  ),
                },
              ],
              title: "",
            },
          ]
        : gpTeam.map(gp => ({
            shadows: 1,
            data: [
              {
                rowStyle: s.rowSubitem,
                name: gp.firstName + " " + gp.lastName,
                labelStyle: styles.label,
                label: t("label.gp"),
                leftChild: (
                  <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                ),
              },
              {
                render: () => <RdvButton id={gp.id} />,
              },
            ],
            title: "",
          }))

    return [
      {
        shadows: 1,
        data: [
          {
            render: () => (
              <View style={s.rowInfo}>
                <View style={s.body}>
                  <Body2>{t("informationWithoutCode")}</Body2>
                  <Body2 selectable style={s.codeView}>
                    {t("myCode", { code })}
                  </Body2>
                  <Button
                    mode="text"
                    labelStyle={styles.buttonLabel}
                    onPress={() => navigation.navigate("CodeInfoScreen")}
                    contentStyle={s.buttonContent}
                  >
                    <Body2 style={s.contactLink} color={primary}>
                      {t("seeMore")}
                    </Body2>
                  </Button>
                </View>
              </View>
            ),
          },
        ],
      },
      ...surgeonSections,
      ...kineSections,
      renderKineSection,
      ...gpSections,
      {
        data: [
          {
            name: t("label.pathology"),
            render: () => (
              <View style={s.rowInfo}>
                <View style={s.body}>
                  <Body2>{t("editMedicalTeam")}</Body2>
                  <Button
                    mode="text"
                    labelStyle={styles.buttonLabel}
                    onPress={() => onPress()}
                    contentStyle={s.buttonContent}
                  >
                    <Body2 style={s.contactLink} color={primary}>
                      {t("supportButton")}
                    </Body2>
                  </Button>
                </View>
              </View>
            ),
          },
        ],
        title: "",
      },
    ]
  }, [
    s,
    t,
    code,
    gpTeam,
    surgeonTeam,
    renderKineSection,
    kineTeam,
    practicianRequestedText,
    onPress,
    primary,
    navigation,
  ])

  return <ParametersScreen listKey="medicalTeamList" sections={sections} />
}

const styles = StyleSheet.create({
  row: {
    height: INPUT_HEIGHT,
  },
  label: {
    paddingBottom: 0,
  },
  shadow: generateShadow(2) as ViewStyle,
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  codeView: {
    textAlign: "center",
  },
})

export default MedicalTeamScreen
