import { IUserContext, Subtitle1, userContext, useTheme } from "capsule"
import React, { FC, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ScrollView, StyleSheet, View } from "react-native"

import { AppUserData } from "../../features/models/UserData"
import { renderFormattedText } from "../../utils/formatBoldTexts"
interface IProps {
  isKine?: boolean
}

const CodeInfoScreen: FC<IProps> = ({ isKine }) => {
  const { t } = useTranslation()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)

  const {
    fontMaker,
    colors: { surface, accent },
    dimensions: { spacing },
  } = useTheme()
  const s = useMemo(
    () => ({
      listView: [
        styles.scrollview,
        {
          padding: spacing,
          backgroundColor: surface.background,
        },
      ],
      infoView: [
        styles.infoView,
        {
          padding: spacing,
          backgroundColor: accent,
          marginBottom: spacing,
        },
      ],
      titleView: [
        fontMaker({ weight: "Bold" }),
        {
          paddingBottom: spacing,
        },
      ],
      codeView: [styles.codeView],
      codeText: [
        fontMaker({ weight: "Bold" }),
        {
          paddingVertical: spacing,
        },
      ],
      footer: [
        styles.centerText,
        fontMaker({ weight: "Bold" }),
        {
          paddingBottom: spacing,
          paddingHorizontal: spacing,
        },
      ],
    }),
    [spacing, fontMaker, surface.background, accent],
  )
  const formatedTextInfo = renderFormattedText(
    t(isKine ? "common:kineInfoText.hasAccountText" : "common:codeInfoText.hasAccountText"),
  )
  return (
    <ScrollView style={s.listView}>
      <Subtitle1>
        {t(isKine ? "common:kineInfoText.header" : "common:codeInfoText.header")}
      </Subtitle1>
      <View style={s.codeView}>
        <Subtitle1 style={s.codeText}>
          {t(isKine ? "common:kineInfoText.yourCode" : "common:codeInfoText.yourCode", {
            code: userData?.code,
          })}
        </Subtitle1>
        <Subtitle1 style={s.codeText} selectable>
          {userData?.code}
        </Subtitle1>
      </View>
      <View style={s.infoView}>
        <Subtitle1 style={s.titleView}>
          {t(
            isKine ? "common:kineInfoText.hasAccountTitle" : "common:codeInfoText.hasAccountTitle",
          )}
        </Subtitle1>
        <Subtitle1>{formatedTextInfo}</Subtitle1>
      </View>
      <View style={s.infoView}>
        <Subtitle1 style={s.titleView}>
          {t(isKine ? "common:kineInfoText.noAccountTitle" : "common:codeInfoText.noAccountTitle")}
        </Subtitle1>
        <Subtitle1>
          {t(isKine ? "common:kineInfoText.noAccountText" : "common:codeInfoText.noAccountText")}
        </Subtitle1>
      </View>
      <Subtitle1 style={s.footer}>
        {t(isKine ? "common:kineInfoText.footer" : "common:codeInfoText.footer")}
      </Subtitle1>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  scrollview: {
    flex: 1,
  },
  centerText: {
    textAlign: "center",
  },
  infoView: {
    borderRadius: 8,
    overflow: "hidden",
  },
  codeView: {
    flexDirection: "row",
    justifyContent: "center",
  },
})

export default CodeInfoScreen
