import { Body2, Button, firestore, openUrl, useTheme } from "capsule"
import firebase from "firebase"
import React, { FC, useMemo } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { OperationNS } from "../../features/i18n/constants"
import { Practician } from "../../features/models/UserData"

interface RdvButtonProps {
  id: string
}

const RdvButton: FC<RdvButtonProps> = ({ id }) => {
  const { t } = useTranslation(OperationNS)
  const [practicianDoc] = useDocumentData<Practician>(
    (firestore()
      .collection("login")
      .doc(id) as unknown) as firebase.firestore.DocumentReference<Practician>,
  )
  const {
    fontMaker,
    colors: {
      white: { highEmphasis: white },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()

  const s = useMemo(
    () => ({
      rowRdv: [
        {
          paddingHorizontal: spacing / 2,
          paddingBottom: spacing / 2,
          backgroundColor: white,
        },
      ],
      contactLink: [fontMaker({ weight: "Bold" })],
    }),
    [spacing, white, fontMaker],
  )

  return (
    <>
      {practicianDoc?.rdvLink ? (
        <View style={s.rowRdv}>
          <Button
            mode="text"
            labelStyle={styles.buttonLabel}
            onPress={() => openUrl(practicianDoc?.rdvLink)}
            contentStyle={styles.buttonContent}
          >
            <Body2 style={s.contactLink} color={primary}>
              {t("rdvButton")}
            </Body2>
          </Button>
        </View>
      ) : null}
    </>
  )
}

const styles = StyleSheet.create({
  buttonContent: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
})

export default RdvButton
