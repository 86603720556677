import { ID } from "./CommonUserData"
import { BaseData } from "./Pathology"

export type QuestionType = "scale" | "text" | "qcu" | "qcm" | "qcm-checkbox" | "date" | "alert"

export type ScaleType = "smiley" | "gauge" | undefined

export type FormResult = Record<ID, string | number | string[] | boolean>

export interface Option extends BaseData<"title" | "description"> {
  id: ID
  value: number
  order: number
  questionId: ID
  disabled: boolean
}

export interface Question extends BaseData<"title" | "description"> {
  id: ID
  formId: ID
  name: string
  order: number
  type: QuestionType
  options: Option[]
  scaleType?: ScaleType
  linkedQuestions?: Question[]
  showIf?: string[]
  linkedValue?: string
  disabled: boolean
}

// positive and negative are use for rating screen text
export interface Form extends BaseData<"title" | "description" | "positive" | "negative"> {
  id: ID
  organs: ID[]
  url?: boolean
  pathologies: ID[]
  title: string
  thanks?: string
  formulas?: string
  specialty?: string
  triggerDay?: number
  triggerDays?: number[]
  triggerTitle?: string
  skipInterstitial?: boolean
  signInForm?: boolean
  questionId?: number
  isEdit?: boolean
  nextForm?: string
  questions: Question[]
  dataGroupName?: string
}

export interface ReviewUrl {
  url?: string
  visible?: boolean
}

// eslint-disable-next-line no-shadow
export enum Interstitial {
  WELCOME,
  END,
  RATING,
  DONE,
  SIGNIN,
}

export interface Score extends BaseData<"title"> {
  id: string
  name: string
  disabled: boolean
}
