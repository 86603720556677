import {
  createMaterialTopTabNavigator,
  MaterialTopTabNavigationOptions,
} from "@react-navigation/material-top-tabs"
import { useTabLand, useTheme } from "capsule"
import _ from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { ActivityIndicator } from "react-native-paper"

import { DoctupSupportedCountries } from "../../features/config/Constants"
import { PracticianListNS } from "../../features/i18n/constants"
import { getFlagEmoji } from "../../utils/conversion"
import PracticianListScreen from "./PracticianListScreen"

const practicianListTabNavigator = createMaterialTopTabNavigator()

const PracticianListNavigator = () => {
  const {
    typography: { button },
    colors: { white, primary, black, surface },
    dimensions: { spacing },
  } = useTheme()
  const isTabLand = useTabLand()

  const countriesAndFlagsArray = useMemo(
    () =>
      _.map(DoctupSupportedCountries, country => ({
        code: country.countryCode,
        title: country.countryName,
        flag: getFlagEmoji(country.countryCode),
        callingCode: country.countryCallingCode,
      })),
    [],
  )

  const { t } = useTranslation()
  const screenOptions = useMemo<MaterialTopTabNavigationOptions>(
    () => ({
      tabBarScrollEnabled: true,
      tabBarActiveTintColor: isTabLand && Platform.OS === "web" ? black.highEmphasis : primary,
      tabBarStyle: {
        backgroundColor:
          isTabLand && Platform.OS === "web" ? white.highEmphasis : surface.background,
      },
      tabBarInactiveTintColor:
        isTabLand && Platform.OS === "web" ? black.mediumEmphasis : black.highEmphasis,
      tabBarIndicatorStyle: {
        height: 2,
        backgroundColor: isTabLand && Platform.OS === "web" ? black.highEmphasis : primary,
      },
      tabBarLabelStyle: { ..._.omit(button, "color"), textTransform: "none" },
      tabBarItemStyle: { width: 130 },
    }),
    [
      black.highEmphasis,
      black.mediumEmphasis,
      button,
      isTabLand,
      primary,
      white.highEmphasis,
      surface.background,
    ],
  )

  const s = useMemo(
    () => ({
      indicator: {
        paddingTop: spacing,
      },
    }),
    [spacing],
  )

  return !countriesAndFlagsArray ? (
    <ActivityIndicator color={primary} style={s.indicator} />
  ) : (
    <practicianListTabNavigator.Navigator {...{ screenOptions }}>
      <>
        <practicianListTabNavigator.Screen
          name="allCountries"
          options={{
            title: t(`${PracticianListNS}:all`),
          }}
        >
          {() => <PracticianListScreen role="kine" />}
        </practicianListTabNavigator.Screen>
        {_.map(countriesAndFlagsArray, country => {
          const title = country.title
          return (
            <practicianListTabNavigator.Screen
              key={country.code}
              name={title}
              options={{
                title,
              }}
            >
              {() => <PracticianListScreen role="kine" country={title} />}
            </practicianListTabNavigator.Screen>
          )
        })}
      </>
    </practicianListTabNavigator.Navigator>
  )
}

export default PracticianListNavigator
