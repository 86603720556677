import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs"
import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  firestore,
  generateShadow,
  IUserContext,
  logger,
  useAlert,
  userContext,
  useTheme,
} from "capsule"
import dayjs from "dayjs"
import firebase from "firebase"
import _, { isEqual } from "lodash"
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import { FlatList, Linking, Platform, StyleSheet, View, ViewStyle } from "react-native"
import { ActivityIndicator } from "react-native-paper"
import { SafeAreaView } from "react-native-safe-area-context"

import { ID } from "../../common/CommonUserData"
import { collections } from "../../common/types"
import { RATING_FORM } from "../../features/config/Constants"
import currentDateMs from "../../features/hooks/useDateMock"
import useHomeActions from "../../features/hooks/useHomeActions"
import useSpecialtySystem from "../../features/hooks/useSpecialtySystem"
import { PatientNS } from "../../features/i18n/constants"
import {
  calculateLimitStartDate,
  findCurrentProgram,
} from "../../features/models/OncoFormFunctions"
import { createDailyPhase, usePhases } from "../../features/models/PhaseAndSessionFunctions"
import { PatientPromsResult } from "../../features/models/Proms"
import { AppUserData } from "../../features/models/UserData"
import { updateUserData } from "../../features/models/UserDataFunctions"
import { programContext } from "../../features/Providers/ProgramProvider"
import { IMainParamList } from "../MainScreen/MainNavigator"
import HomeFaqView from "./HomeFaqView"
import HomeHeader from "./HomeHeader"
import HomeNoProgramView from "./HomeNoProgramView"
import HomePromsByIdView from "./HomePromsByIdView"
import HomeSessionView from "./HomeSessionView"
import HomeTodoView from "./HomeTodoView"

export type HomeScreenNavigationProp = BottomTabNavigationProp<IMainParamList, "Home">

function capitalizeFirstLetter(role: string) {
  return role.charAt(0).toUpperCase() + role.slice(1)
}

const HomeScreen: FC = () => {
  const {
    fontMaker,
    colors: {
      primary,
      surface: { background },
      accent,
    },
    dimensions: { spacing },
  } = useTheme()
  const { user, userData, userDocRef } = useContext<IUserContext<AppUserData>>(userContext)
  const { showDialog } = useAlert()
  const { t } = useTranslation(PatientNS)
  const navigation = useNavigation<any>()
  // PHASES
  const hasFollowRequest =
    userData?.practiciansRequested && userData?.practiciansRequested.length > 0
  const { currentPhase, cleanPhase } = useContext(programContext)
  const { initDailyPhase, createLocalNotifications } = useSpecialtySystem()
  const isProgramEnded = dayjs(currentDateMs()).isAfter(userData?.programEnd as Date, "day")
  const [isExistingProg, setIsExistingProg] = useState<boolean | undefined>(undefined)
  const { initialLoading } = useHomeActions()
  const { phaseNotTodos } = useContext(programContext)
  const prevUserDataRef = useRef(
    userData
      ? {
          alertDays: userData.alertDays,
          alertEndTime: userData.alertEndTime,
          alertStartTime: userData.alertStartTime,
          program: userData.program,
          surgeryDate: userData.surgeryDate,
          timezone: userData.timezone,
        }
      : {},
  )
  // @ts-ignore
  const startPhase = usePhases(userData?.surgeryDate, "asc")

  const usePromsByFormId = (formId: ID) =>
    useCollectionData<PatientPromsResult & { docId: string }>(
      userDocRef
        ? ((userDocRef
            .collection(collections.PROMS)
            .where("id", "==", formId) as unknown) as firebase.firestore.Query<PatientPromsResult>)
        : null,
      { idField: "docId" },
    )

  const [ratingProms] = usePromsByFormId(RATING_FORM)

  const initNewPhase = useCallback(async () => {
    if (!userData || !startPhase) {
      return
    }
    const result = await createDailyPhase({
      user,
      phase: startPhase,
      userData,
      initDailyPhase,
      createLocalNotifications,
    })
    if (!result) {
      cleanPhase()
    }
  }, [cleanPhase, createLocalNotifications, initDailyPhase, userData, user, startPhase])

  useEffect(() => {
    const isUpdateNeeded = () => {
      const fieldsToCompare = [
        "program",
        "alertEndTime",
        "alertStartTime",
        "alertDays",
        "surgeryDate",
        "timezone",
      ]
      const newUserSubset = {}
      const prevUserSubset = {}
      fieldsToCompare.forEach(field => {
        newUserSubset[field] = userData?.[field]
        prevUserSubset[field] = prevUserDataRef.current?.[field]
        if (field === "program" && newUserSubset[field] !== prevUserSubset[field]) {
          userDocRef?.set(
            {
              doneTasks: firestore.FieldValue.delete(),
            },
            { merge: true },
          )
        }
      })
      if (startPhase && userData?.phase !== startPhase?.id) {
        updateUserData(userDocRef, {
          phase: startPhase.id,
        })
      }
      return !isEqual(newUserSubset, prevUserSubset)
    }
    if (isUpdateNeeded()) {
      initNewPhase()
    }
    prevUserDataRef.current = userData
      ? {
          program: userData?.program,
          alertEndTime: userData?.alertEndTime,
          alertStartTime: userData?.alertStartTime,
          alertDays: userData?.alertDays,
          surgeryDate: userData?.surgeryDate,
          timezone: userData?.timezone,
        }
      : {}
  }, [userData, initNewPhase, userDocRef, startPhase])

  useEffect(() => {
    const getUserPrograms = async () => {
      try {
        if (!startPhase || !userData) {
          return
        }
        if (userData && userData.program && !userData.phase) {
          updateUserData(userDocRef, {
            phase: startPhase.id,
          })
        }
        const programDocs = (await userDocRef?.collection(collections.PROGRAMS).get())?.docs
        if (programDocs && programDocs.length > 0) {
          setIsExistingProg(true)
          if (isProgramEnded) {
            const programsDates = programDocs.map(prog => ({
              type: prog.data().surgeryType,
              date: dayjs(prog.data().start.seconds * 1000).toDate(),
            }))
            const limitStartDate = calculateLimitStartDate(userData)
            const validProgDates = programsDates.filter(
              validProg => validProg.date >= limitStartDate,
            )
            const newProgramDate = await findCurrentProgram(validProgDates, userData)
            if (newProgramDate) {
              const newProgram = await userDocRef
                ?.collection(collections.PROGRAMS)
                .where("start", "==", newProgramDate)
                .get()
              const newProgData = newProgram?.docs[0].data()
              const newProgId = newProgram?.docs[0].id
              const updateSurgeryDate = dayjs(newProgramDate).format("YYYY-MM-DD")
              updateUserData(userDocRef, {
                phase: startPhase.id,
                programEnd: newProgData?.end,
                programStart: newProgData?.start,
                program: newProgId,
                surgeryDate: updateSurgeryDate,
              })
              initNewPhase()
            }
          }
        } else {
          setIsExistingProg(undefined)
        }
      } catch (error) {
        logger("Error when retrieving programs :", error)
      }
    }
    if (userData?.specialty === "onco") {
      getUserPrograms()
    }
  }, [userDocRef, isProgramEnded, startPhase, userData, initNewPhase])

  const handleRequestModal = useCallback(
    (practicianLinked: boolean | undefined, role: string, seeList?: boolean) => {
      if (practicianLinked === false && seeList) {
        const formattedRole = capitalizeFirstLetter(role)
        navigation.navigate(`${formattedRole}List`)
      }
      userDocRef?.set(
        {
          [`${role}Linked`]: firestore.FieldValue.delete(),
        },
        { merge: true },
      )
    },
    [navigation, userDocRef],
  )

  useEffect(() => {
    const linkedFields = ["kine", "surgeon"]
    const matchingLinkedFields = linkedFields.filter(
      field => userData?.[`${field}Linked`] || userData?.[`${field}Linked`] === false,
    )
    if (userData && matchingLinkedFields.length > 0) {
      const practicianRole = matchingLinkedFields[0]
      const isKine = practicianRole === "kine"
      const isLinkedAprouved = userData[`${practicianRole}Linked`] === true
      showDialog({
        type: "button",
        title: isLinkedAprouved
          ? t(`${PatientNS}:${practicianRole}LinkedModal.title`)
          : t(`${PatientNS}:${practicianRole}NotLinkedModal.title`),
        message: isLinkedAprouved
          ? t(`${PatientNS}:${practicianRole}LinkedModal.text`, {
              ns: PatientNS,
              kineName: isKine ? userData?.kineName : userData?.surgeonName,
            })
          : t(`${PatientNS}:${practicianRole}NotLinkedModal.text`),
        dismissable: false,
        positive: {
          onPress: () => {
            handleRequestModal(
              userData[`${practicianRole}Linked`],
              practicianRole,
              isLinkedAprouved ? undefined : true,
            )
          },
          label: isLinkedAprouved
            ? t(`${PatientNS}:${practicianRole}LinkedModal.validate`)
            : t(`${PatientNS}:${practicianRole}NotLinkedModal.validate`),
          labelType: "cancel",
        },
        negative: !isLinkedAprouved
          ? {
              onPress: () => {
                handleRequestModal(userData[`${practicianRole}Linked`], practicianRole)
              },
              label: t(`${PatientNS}:${practicianRole}NotLinkedModal.cancel`),
            }
          : undefined,
      })
    }
  }, [userData, t, showDialog, handleRequestModal])

  const APP_STORE_ID = "1574145501"
  const PLAY_STORE_ID = "com.siruplab.doctup"

  useEffect(() => {
    const handleStoreReview = async () => {
      if (userData?.hasRequestedReview === true) {
        showDialog({
          type: "button",
          title: t(`${PatientNS}:storeReview.title`),
          message: t(`${PatientNS}:storeReview.message`),
          dismissable: false,
          positive: {
            onPress: async () => {
              try {
                const storeUrl = Platform.select({
                  ios: `itms-apps://apps.apple.com/app/id${APP_STORE_ID}?action=write-review`,
                  android: `market://details?id=${PLAY_STORE_ID}`,
                  default: "",
                })

                await Linking.openURL(storeUrl)
              } catch (error) {
                const webStoreUrl = Platform.select({
                  ios: `https://apps.apple.com/app/${APP_STORE_ID}?action=write-review`,
                  android: `https://play.google.com/store/apps/details?id=${PLAY_STORE_ID}`,
                  default: "",
                })
                try {
                  await Linking.openURL(webStoreUrl)
                } catch (fallbackError) {
                  logger("Failed to open store review page:", fallbackError)
                }
              }
            },
            label: t(`${PatientNS}:storeReview.accept`),
          },
          negative: {
            onPress: () => {},
            label: t(`${PatientNS}:storeReview.decline`),
          },
        })

        await updateUserData(userDocRef, {
          hasRequestedReview: false,
        })
      }
    }

    handleStoreReview()
  }, [userData?.hasRequestedReview, userData?.activityTracking, userDocRef, showDialog, t])

  const s = useMemo(
    () => ({
      statusBar: [
        styles.view,
        {
          backgroundColor: primary,
        },
      ],
      view: [
        styles.view,
        {
          backgroundColor: background,
        },
      ],
      indicator: {
        marginTop: spacing * 2,
      },
      rowInfo: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
        },
      ],
      contactLink: [fontMaker({ weight: "Bold" })],
      body: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
    }),
    [background, primary, spacing, fontMaker, accent],
  )
  const FlatListComponents = [
    <HomeSessionView key="HomeSession" />,
    userData?.displayTodos !== false ? (
      <HomeTodoView key="HomeTodo" type="phaseTodos" />
    ) : undefined,
    !_.isEmpty(phaseNotTodos) ? <HomeTodoView key="HomeNotTodo" type="phaseNotTodos" /> : undefined,
    <HomeFaqView key="HomeFaq" />,
  ].filter(Boolean)

  const showSatisfaction = currentPhase?.showSatisfaction && _.isEmpty(ratingProms)

  return (
    <SafeAreaView style={s.statusBar} edges={["top"]}>
      <View style={s.view}>
        <HomeHeader />
        {initialLoading ? (
          <ActivityIndicator style={s.indicator} size="large" />
        ) : (
          <>
            {isProgramEnded || (!isExistingProg && userData?.specialty === "onco") ? (
              <HomeNoProgramView isProgramEnded={isProgramEnded} />
            ) : (
              <FlatList
                data={FlatListComponents}
                renderItem={({ item }) => <>{item}</>}
                ListHeaderComponent={
                  <>
                    {hasFollowRequest ? (
                      <View style={s.rowInfo}>
                        <View style={s.body}>
                          <Body2>{t(`${PatientNS}:followRequestInfo.text`)}</Body2>
                          <Button
                            mode="text"
                            labelStyle={styles.buttonLabel}
                            onPress={() => navigation.navigate("KineRequestedScreen")}
                            contentStyle={styles.buttonContent}
                          >
                            <Body2 style={s.contactLink} color={primary}>
                              {t(`${PatientNS}:followRequestInfo.button`)}
                            </Body2>
                          </Button>
                        </View>
                      </View>
                    ) : null}
                    {showSatisfaction && <HomePromsByIdView formId={RATING_FORM} />}
                  </>
                }
              />
            )}
          </>
        )}
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  view: {
    flex: 1,
  },
  shadow: generateShadow(2) as ViewStyle,
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
})

export default HomeScreen
