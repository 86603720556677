import {
  ActivationCodeNS,
  AlertNS,
  CguNS,
  CongratsNS,
  DeleteAccountNS,
  EvaluationNS,
  ExerciseNS,
  ExercisesListNS,
  FaqNS,
  HomeNS,
  JourneyNS,
  LanguageNS,
  MainNS,
  NavigationNS,
  OperationNS,
  PatientNS,
  PatientsListNS,
  PractitionerHomeNS,
  PraticienNS,
  ProfileNS,
  PromsNS,
  ProtocolNS,
  SettingsNS,
  SMSValidationNS,
  VideoNS,
} from "./constants"

const onboarding = "Enter the $t(common:appName) 8-digit activation code."
const logoutPractitioner = "Are you a physician ?"

export default {
  common: {
    activityCalendar: {
      title: "Activity tracking",
      programInfo:
        "💡 Your rehabilitation program consists of different exercise sessions adapted to each pre and post-operative stage.\nIt will accompany you throughout your journey towards functional recovery.",
      oneActivityDay: "Congratulations 🥳\nYou've completed your today's session!",
      activityDay: "Congratulations 🥳\nYou've completed all of today's sessions!",
      inactivityDay: "No activity indicated",
      inactivityDayToday: "You haven't done your workout today  💪",
      oneSessionLeft: "Well done 🥳\nYou have completed {{sessionNumber}} exercises session today!",
      oneSessionsDone:
        "You have achieved {{sessionNumber}}/{{totalSessions}} session today.\nKeep it up, you're on the right track 🔥",
      sessionsDone:
        "You have achieved {{sessionNumber}}/{{totalSessions}} sessions today.\nKeep it up, you're on the right track 🔥",
      noExercicesToday: "💡 You don't have any exercises planned for today ✨",
      programToday: "🗓️ Your program today",
      programStart: "Beginning of your program 💪",
      programEnd: "End of your program 🥇",
      surgeryDay: "💡 Your surgery day",
      currentPhase: "Current session ⏳",
      startSession: "Start session",
      startNextSession: "Start next session",
      acceptShareContact: "I accept",
    },
    alert: {
      dismiss: "Dismiss the modal",
      photo: {
        camera: "Take a picture",
        title: "Add a report",
        documents: "Select a file",
      },
    },
    appName: "DOCT'UP",
    accountScreen: {
      termsOfUse:
        "<p>By registering you agree to the <a href='https://www.doctup.fr/gcv-cgu'>Terms of Use</a> and <a href='https://www.doctup.fr/mentions-l%C3%A9gales'>Privacy Policy</a>.</p>",
    },
    addMail:
      "In order to improve your experience with Doct'UP and facilitate the distribution of patient codes, we would be grateful if you could share some information with us, such as your e-mail address and, optionally, that of your secretary. In addition, we'd like to better understand how you use Doct'UP on a daily basis.",
    button: {
      /** common */
      stop: "Stop",
      cancel: "Cancel",
      continue: "Continue",
      dateCheckbox: "I don't know the date",
      delete: "Delete account",
      ended: "End",
      logout: "Log out",
      modify: "Modify",
      operation_type_unknown: "I don't know the type of surgery",
      negative: "Cancel",
      no: "no",
      noThanks: "No, thanks",
      ok: "OK",
      positive: "OK",
      save: "Save",
      start: "Start",
      validate: "Validate",
      yes: "yes",
      pathology_list: "Edit my informations",
      surgery_list: "Add an operation",
      skip: "Skip this step",

      /** Alert */
      neutral: "Modify",
      stopSession: "Back to session",
    },
    fields: {
      label: {
        lastName: "Last name",
        firstName: "First name",
        email: "Email",
        otherEmail: "Secretary email (optional)",
        street_number: "Street number",
        route: "Route",
        additional_address: "Additional address (optional)",
        postal_code: "Postal Code",
        locality: "City",
        country: "Country",
        France: "France",
        Suisse: "Switzerland",
        Inde: "India",
      },
      inputInfo: {
        route: "Example: Richmond Street",
        additional_address: "Example: Building, code, floor ...",
      },
    },
    landing: {
      signUp: "Patient Login",
      signIn: "Physician Login",
    },
    list: {
      empty: {
        title: "Empty list",
        subtitle: "No items found",
        comingSoon: "Coming soon",
      },
      error: {
        title: "Error",
        subtitle: "An error occurred while loading the data.",
      },
    },
    parameters: {
      account: "Account",
      help: "Help",
      privacy: "Privacy Policy",
      termsOfUse: "Terms Of Use",
    },
    patientRequestNotif: {
      title: "Patient Request",
      text: "You have a new patient follow-up request 🎉",
      multipleTitle: "Patient Requests",
      mutipleText: "You have new patient follow-up requests 🎉",
      channel: "request",
    },
    permission: {
      message: "We need your permission to take pictures.",
    },
    PhoneNumberScreen: {
      placeholder: "We need your permission to take pictures.",
      label: "Add a phone number",
      caption: "We use your phone number to send you your SMS validation code.",
    },
    signUp: {
      termsOfUse:
        "<p>By continuing you indicate that you agree with our <a href='https://www.doctup.fr/gcv-cgu'>Terms and Conditions</a> and <a href='https://www.doctup.fr/mentions-l%C3%A9gales'>Privacy Policy</a>.</p>",
    },
    SMSInputScreen: {
      caption:
        "To validate your account, please enter the code you will receive by SMS at\n<1>{{phone}}</1>",
      subtitle: "Didn't receive anything? <1>Send the code again</1>",
    },
    AddressEdition: "Work Address",
    AddressInfo: "💡 Make it easier for patients to identify you.",
    KineSpecialtiesEdition: "Specialties",
    KineDataScreen: "Doct'UP Referencing",
    PatientsRequests: "Patient requests",
    RdvUrl: "Appointment site",
    ShareContactEdition: "Sharing authorization",
    unknown: {
      name: "Unknown name",
    },
    upgrade: {
      title: "Update available 🎉",
      button: "Update",
      error: "Error verifying version : ",
    },
    version: "Version {{version}}",
    kineTodo:
      "💡 To allow your physiotherapist to track your progress and adjust your treatment plan, don't forget to add them to your account.\n\nYou have two options to do this:\n\n📌 Share your code with your physiotherapist.\n\n✻ YOUR CODE: {{code}}\n\n- If they already have a Doct'UP account:\nThey just need to go to the Patients tab and select the 'Add a patient' button.\nThey can then enter your 8-character code.\n\n- If they don’t have a Doct'UP account:\nThey will first need to download the app on their phone.\nDuring account creation, after entering their phone number and the 6-digit code sent by SMS, they will be asked to enter your 8-character activation code.\n\n📌 Search for and add your physiotherapist from our list of Doct'UP-registered professionals:\n",
    codeInfoText: {
      header:
        "💡 To allow your physiotherapist or physician to track your progress, share your code with your healthcare professionals.",
      yourCode: "🔑 YOUR CODE: ",
      hasAccountTitle: "📍 If they already have a Doct'UP account",
      hasAccountText:
        "He will need to:\n✧ go to the [b]Patients[/b] tab and select the [b]Add Patient[/b] button\n✧ enter your 8-character code.",
      noAccountTitle: "📍 If they don't have a Doct'UP account",
      noAccountText:
        "They will need to:\n✧ download the app\n✧ enter their phone number\n✧ enter the 6-digit code received by SMS\n✧ enter your 8-character code.",
      footer: "Your healthcare professional will be able to track your progress! 🎉",
    },
    kineInfoText: {
      header:
        "💡 If you can't find your physiotherapist in our list, you can share your activation code with them.\n\nThis will allow them to track your progress and adjust your protocol.",
      yourCode: "🔑 YOUR CODE: ",
      hasAccountTitle: "📍 If they already have a Doct'UP account",
      hasAccountText:
        "He will need to:\n✧ go to the [b]Patients[/b] tab and select the [b]Add Patient[/b] button\n✧ enter your 8-character code.",
      noAccountTitle: "📍 If they don't have a Doct'UP account",
      noAccountText:
        "They will need to:\n✧ download the app\n✧ enter their phone number\n✧ enter the 6-digit code received by SMS\n✧ enter your 8-character code.",
      footer:
        "Your physiotherapist will be able to track your progress and adjust your protocol accordingly! 🎉",
    },
  },
  [ActivationCodeNS]: {
    title: "Activation Code",
    label: {
      surgeon: onboarding,
      kine: onboarding,
      patient:
        "<p>Your practician has given you an 8-digit activation code." +
        " \n" +
        "<b>This code links your account to his.</b>" +
        " \n" +
        "It will only be asked one time.</p>",
      addPatient: "Enter the code provided by your patient to add them to your list.",
      addPatientInfo:
        "💡 If you have given them a code, it will appear automatically in your list once they have created their account on Doct'UP.",
      gp: onboarding,
      practitioner: onboarding,
    },
    logout: {
      kine: logoutPractitioner,
      gp: logoutPractitioner,
      logoutPractitioner,
      patient: "Aren't you a patient ?",
      surgeon: "Aren't you a surgeon ?",
      button: "Choose another profile",
    },
    errors: {
      code_invalid: "The code entered is invalid, please try again (case sensitive)",
      code_surgeon: "This code has not been activated. Contact your surgeon to activate it.",
      code_kine: "This code has not been activated. Contact your physiotherapist to activate it.",
      code_gp: "This code has not been activated. Contact your doctor to activate it.",
      code_patient: "Your patient has not yet created a Doct'up account. Please contact them.",
      activate_pack:
        "Pack activated. Your patients will appear in your list once their account is created.",
      code_already_activated: "The code entered has already been used.",
      patient_activation_code_surgeon:
        "You have entered a surgeon code. Contact your surgeon to obtain a valid patient code.",
      patient_activation_code_kine:
        "You have entered a physiotherapist code. Contact your physiotherapist to obtain a valid patient code.",
      patient_activation_code_gp:
        "You have entered a doctor's code. Contact your doctor to obtain a valid patient code.",
      surgeon_activation_code:
        "Code not activated. Please enter the surgeon code supplied in your pack.",
      kine_activation_code:
        "Code not activated. Please enter the physiotherapist code supplied in your pack.",
      gp_activation_code: "Code not activated. Please enter the doctor code supplied in your pack.",
      input: "Your code contains non-alphanumeric characters.",
      code_pack: "An error occurred while creating the codes.",
      unknown: "An error occurred while verifying the code.",
      code_blocked: "Exceeded number of allowed trials. Please try again later.",
    },
  },
  [AlertNS]: {
    dialog: {
      title: "Do you want to enable alarms ?",
      message: "$t(common:appName) wants to send you push notifications.",
    },
    label: {
      start: "Start time",
      startTraining: "Training time",
      end: "End time",
      Monday: "Mon",
      Tuesday: "Tue",
      Wednesday: "Wed",
      Thursday: "Thu",
      Friday: "Fri",
      Saturday: "Sat",
      Sunday: "Sun",
    },
    explanation:
      "Your training consists of one session per day, every day for 21 days.\nWhat time would you like to train?",
    errors: {
      min: "The end time must be greater than the start time",
      max: "The start time must be less than the end time",
    },
    helper: "You can change your alarms at any time to suit your daily routine.",
    subtitle: "To improve your assiduity, choose when to receive your alarms.",
    subtitle2:
      "💡 During this time, you could have several exercise sessions based on your protocol.",
    channel: {
      phase: "Progression",
      session: "Workout session",
    },
    notification: {
      nextPhase: "A new program has started",
      title: "Get ready for your reeducation session",
      body: "Assiduity is the key to success 👍",
    },
    patientRequestNotif: {
      title: "Patient Request",
      text: "You have a new patient follow-up request 🎉",
      multipleTitle: "Patient Requests",
      mutipleText: "You have new patient follow-up requests 🎉",
      channel: "request",
    },
  },
  [CguNS]: {
    accept: "Accept all",
    refuse: "Refuse all",
    more: "Read more",
    title: {
      patient: "The security of your data is a top priority for us",
      practitioner: "The security of your patients' data is a top priority for us",
    },
    address1: "💡 Be at the heart of patient follow-up on Doct’UP!",
    address2:
      "\nSimplify your procedures by listing your professional address, specialties, and by facilitating appointment scheduling.",
    address3: "\nOptimize your visibility and ensure quick access to quality care for patients.",
    addressConfirm:
      "I agree that my professional information (address, specialty(ies), and appointment link) can be shared with patients using Doct’UP.",
    disableTitle: "Important",
    disableMessage:
      "Registering on Doct’UP simplifies the follow-up of your patients and optimizes your visibility.\n\nYou can modify your choices at any time by going to the Account space, under the Doct'UP Listing tab, in the application.",
    understand: "J'ai compris",
    researchLabel: "I agree that my data may be used for research purposes",
    research:
      "<p> The information collected on this form is recorded in a file computerized by <b>Google Cloud</b> for the proper functioning of the application OR for statistical purposes. The legal basis for the processing is consent.\n</p>" +
      " \n" +
      "The data collected will be communicated only to the following recipients :\n" +
      "<b>the Doct'Up team, your physician, the subcontractors that Doct'Up may use for any technical operation involving your personal data, our health data host Google Cloud.</b>\n" +
      " \n" +
      "The data is stored for <b>24 months</b>.\n" +
      " \n" +
      " You can access your data, rectify it, request its deletion or exercise your right to limit the processing of your data. <b>You can also exercise your right to data portability.\n" +
      "You can withdraw your consent to the processing of your data at any time, as well as object to it</b>.\n" +
      " \n" +
      "Visit cnil.fr website for more information on your rights.\n" +
      " \n" +
      "To exercise these rights or for any questions about the processing of your data in this device, you can contact: contact@doctup.fr\n" +
      " \n" +
      "If you consider, after having contacted us, that your rights « Information Technology and Liberties » have not been respected, you may submit a complaint to the CNIL.\n</p>",
    resume: {
      patient:
        "Within the framework of the use of the Application, the Health Professional User or yourself have transmitted to the Company some of your personal data and in particular :\n" +
        "The data that you fill in when accessing your account by filling in the dedicated form :\n" +
        "- ID, patient code, date of surgery, joint operated on, type of graft, meniscal lesion, age, height, weight, sport level.\n" +
        " \n" +
        "Data related to your rehabilitation journey : this is the data you publish to inform the Healthcare Professional User of your progress in your self-rehabilitation journey and your feelings about the rehabilitation.\n" +
        "By clicking on the appropriate consent box, you give your express consent to the collection and processing of such personal data, by the Company acting as a subcontractor of the Healthcare Professional User. The latter is responsible for processing in the sense and under the conditions provided for by Law n°78-17 of 6 January 1978 as amended and by EU Regulation 2016/179 as of its entry into force (hereinafter together « the Regulation »).\n" +
        "These personal data are collected and processed for the purpose of operating the Application, managing the Service, ensuring the proper execution of these T&Cs and for statistical purposes.\n" +
        "The aforementioned personal data will be kept for the duration of the contractual relationship and 24 months from the end of this relationship, or from the request to close your account.\n" +
        "The Company will not make any commercial use of your personal health data collected through the Application.\n" +
        "Your attention has been specifically drawn to the sensitivity of the health data you provide in the context of the Application, which is also covered by medical professional secrecy.\n" +
        "Your personal data are strictly intended for :\n" +
        "   • To specifically authorized members of the Company ;\n" +
        "   • To the Healthcare Professional User who invited you to use the Application ; in any case, the Company shall be held responsible for the communication of your data to third parties by the Healthcare Professional User.\n" +
        "   • To subcontractors used by the Company for any technical operation involving your data within the framework of sufficient guarantees for the implementation of appropriate technical and organizational measures, in particular in terms of confidentiality and security ;\n" +
        "   • To the strictly authorized administrators of the approved health data host, as defined in article L.1111-8 of the Public Health Code.\n" +
        "The Company, or the Healthcare Professional User, may not publish any data about you without your consent.\n" +
        "In accordance with the applicable regulations, you are informed that you benefit from :\n" +
        "   • a right of access to the information that concerns you, as well as a right to the portability of your data, which allows you to recover part of these data to transmit them to another controller ;\n" +
        "   • a right to modify or rectify your personal data ;\n" +
        "   • the right to define the directives relating to the conservation, the erasure and the communication of your personal data post-mortem ;\n" +
        "   • a right of limitation, deletion and opposition to the processing of information about you.\n" +
        "Where processing is based on your consent, you have the opportunity to withdraw your consent to the processing of your personal data at any time.\n" +
        "To exercise your rights, you can send a request by writing to the following address : contact@doctup.fr\n" +
        "You have the possibility of lodging a complaint with the National Comity of the Information Technology and Liberties.\n",
      practitioner:
        "In the course of using the Application, you or your patient have provided the Company with certain personal data, including :\n" +
        "The data that your patient fills in when accessing his account by filling in the dedicated form :\n" +
        "- ID, patient code, date of surgery, joint operated on, type of graft, meniscal lesion, age, height, weight, sport level.\n" +
        "\n" +
        "Data related to your patient's self-rehabilitation pathway : this is the data that your patient will publish to inform you of his progress in his self-help journey and his feelings about the rehabilitation. By clicking on the appropriate consent box, your patient has given his or her express consent to the collection and processing of his or her personal data by the Company acting as a subcontractor of you, the practitioner (hereinafter « the Healthcare Professional User »). The latter is a data controller within the meaning and under the conditions provided for by Law No. 78-17 of January 6, 1978, as amended, and by EU Regulation 2016/179 as of its entry into force (hereafter together « the Regulation »).\n" +
        "These personal data are collected and processed for the purpose of operating the Application, managing the service, ensuring the proper execution of these T&Cs and for statistical purposes.\n" +
        "The aforementioned personal data will be kept for the duration of the contractual relationship and 24 months from the end of this relationship, or from the request to close your account. The Company will not make any commercial use of your personal health data collected through the Application.\n" +
        "Your attention has been specifically drawn to the sensitivity of the health data that your patient provides in the context of the Application, also covered by your medical professional secrecy.\n" +
        "Your patient's personal data is strictly for :\n" +
        "   • To specifically authorized members of the Society ;\n" +
        "   • To you, the Healthcare Professional User, who invited him/her to use the Application; under no circumstances shall the Company be held responsible for the disclosure of patient data by the Healthcare Professional User to third parties ;\n" +
        "   • To subcontractors used by the Company for any technical operation involving your patient's data within the framework of sufficient guarantees with regard to the implementation of appropriate technical and organizational measures, in particular in terms of confidentiality and security ;\n" +
        "   • To the strictly authorized administrators of the approved host of health data, within the meaning of article L. 1111-8 of the Public Health Code. The Company, or the Healthcare Professional User, may under no circumstances publish data concerning the patient without his consent.\n" +
        "In accordance with applicable regulations, you and your patient are informed that you are entitled to :\n" +
        "   • A right of access to the information that concerns you, as well as a right to the portability of your data, which allows you to recover part of these data to transmit them to another controller ;\n" +
        "   • A right to modify or rectify your personal data ;\n" +
        "   • A right to define the directives relating to the conservation, the erasure and the communication of your personal data post-mortem ;\n" +
        "   • A right to limit, delete and oppose the processing of information about you.\n" +
        "Where processing is based on your consent, you have the opportunity to withdraw your consent to the processing of your personal data at any time.\n" +
        "To exercise this right, you can send a request by writing to the following address : contact@doctup.fr\n" +
        "You have the possibility of lodging a complaint with the National Commission for Data Processing and Liberties.\n",
    },
  },
  [CongratsNS]: {
    session: "You have completed your session",
    misession: "Congratulations, your session is almost over !",
    phase: "You move on to the next phase of your program",
    end: "You have completed your program",
    program: "You have completed your program for today",
  },
  [DeleteAccountNS]: {
    title:
      "You have the option to delete your account. All data related to your account will be deleted within 15 days.",
    warningParagraph:
      "<p><b>Careful! The deletion of your account is permanent</b>, you will not be able to retrieve your data.</p>",
    validateDeletion:
      "<p>If you still want to delete your account, click on<b>Delete your account.</b>\n</p>",
  },
  [EvaluationNS]: {
    exercise: "Evaluate your exercise",
    evening: "Evaluate your session",
    increaseDifficulty:
      "Congratulations on your efforts! Would you be willing to move to a more advanced level of this exercise starting tomorrow?",
    morning: "How do you feel today ?",
    difficulty: "How difficult were the exercises?",
    morningPain: "What is your pain level?",
    eveningPain: "What was your pain level?",
    fatigue: "What is your fatigue level?",
    stiffness: "Do you feel your joint stiff?",
    information: "💡 Your evaluation is shared with your surgeon for consultation.",
    medium: "Medium",
    efforts: {
      easy: "Easy",
      important: "Important",
      difficult: "Difficult",
      maximal: "Maximal",
    },
    pains: {
      none: "None",
      weak: "Weak",
      strong: "Strong",
      intolerable: "Intolerable",
    },
  },
  [ExerciseNS]: {
    abandon: "Yes, I want to stop",
    next: "Next",
    previous: "Previous",
    sessionOfTheDay: "Session of the day",
    exercise: "Exercise",
    cannot: "I cannot do this exercise",
    play: "Play",
    pause: "Pause",
    goals: "Goals",
    instructions: "Instructions",
    errors: "Mistakes to avoid",
    progression: "Progressions",
    empty: "An error occurred while loading the video",
    alert: {
      title: "Leave the session ?",
      message: "Stop the session will end it.",
    },
    oncoAlert: {
      title: "Stop the session",
      message: "If you stop now, you will have to restart the session from the beginning",
    },
  },
  [ExercisesListNS]: {
    sectionTitle: "Exercises",
  },
  [FaqNS]: {
    tabs: {
      before: "Before the surgery",
      after: "After the surgery",
    },
    chips: {
      tutorial: "Tutorial",
      information: "Information",
      document: "Document",
    },
  },
  [HomeNS]: {
    todo: "To Do",
    faq: "FAQ",
    fillSatisfaction: "Give your feedback",
    start: "Start",
    startSession: "Start the session",
    sessionToCome: "Your program will start on:",
    sessionToComeButton: "Program to come",
    seeMore: "See more",
    inquire: "Getting informed",
    session: "{{time}} session",
    seeExercises: "See the exercises",
    endedSession: "Session completed",
    comeBack: "Come back at {{slot}} for the session",
    title: "👋 Welcome {{firstName}} {{lastName}}",
    titleSurgeon: "👋 Welcome Dr {{lastName}}",
    subtitle: "Your program of the day",
    nextTime: "Next session",
    todoEnd: "You have finished your tasks for now! 👍",
    todoToCome: "💡 You'll find your tasks here.",
    notTodo: "Things to avoid",
    sessionEnd: "You have finished your exercises for today ✨",
    noExo: "You don't have any exercises planned for today ✨",
    description: "{{count}} exercise • {{time}} min",
    description_plural: "{{count}} exercises • {{time}} min",
    descriptionWithoutEquipments: "$t(description)",
    descriptionWithEquipments: "$t(description) • {{equipment}}",
    descriptionWithEquipments_plural: "$t(description_plural) • {{equipment}}",
    takeTime: "Take the time to do the exercises at your own pace",
    dialog: {
      label: "I understand",
      message: "Please connect to $t(common:appName) using the mobile application.",
    },
    alert: {
      title: "Workout session in progress",
      message: "The session was interrupted, do you wish to continue it ? ",
      yes: "Yes, continue",
    },
    protocolEnd: {
      title: "✔️ Completed program",
      ortho: {
        button: "Start a new program",
        text: "Congratulations!\nYou have completed your rehabilitation protocol 🎉",
      },
      onco: {
        button: "Enter a date",
        text:
          "Congratulations!\nYou have completed your rehabilitation protocol 🎉\nIf you have other surgery dates planned, don't forget to let us know so that Doct'Up can offer you a program adapted to your surgery.",
      },
    },
    enterDate: {
      title: "Enter a date",
      text: "Please enter a surgery date to start your rehabilitation program.",
    },
  },
  [JourneyNS]: {
    header: {
      hello: "Hello",
      congrats: "Congratulations",
      preop: "💡 Your operation is scheduled in {{numberOfDays}} days",
      tomorrow: "💡 Your operation is scheduled for tomorrow!",
      surgeryDay: "Your operation is scheduled for today 💪",
      activityDays: {
        title: "{{numberOfDays}} consecutive days of activity 🔥",
        recovery: "Congratulations on your commitment!\nEvery effort counts 🌟",
        lessThan3: "Remember, consistency is the key to success!",
        lessThan6: "Keep up the good work, consistency is key!",
        lessThan10: "Regularity leads to success!",
        moreThan10: "Your commitment is truly remarkable!",
      },
      inactivityDays: "Resume your exercises today and feel the difference 💪",
    },
    activityTracking: {
      title: "Activity tracking",
      calendarButton: "See the calendar",
    },
    exercicesView: {
      addAdditionalDays: "Add additional days to the current phase",
      addedDaysInfoKine: "📅 Additional days have been added to this phase.",
      addedDaysInfoPatient:
        "💡 Your physiotherapist has added {{addedDaysNumber}} days to this stage of your program to strengthen your recovery.",
      additionalDays: "Number of additional days to add:",
      advanceToNextPhaseKine: "🚀 You have advanced the patient to this new phase.",
      advanceToNextPhase: "🚀 Your physiotherapist has advanced you to this new phase.",
      buttonCurrentPhase: "Extend current phase",
      buttonEditCurrentPhase: "Adjust Extension",
      buttonGoBackPhase: "Return to this phase",
      buttonNextPhase: "Move to this phase",
      confirmNextPhase: "Are you sure you want to move your patient to this new phase?",
      confirmationMessage: "Modification Completed",
      confirmationMessageNextPhase:
        "You have advanced the patient to a new phase of their rehabilitation protocol.",
      confirmationMessagePreviousPhase:
        "You have returned the patient to the previous phase of their rehabilitation protocol.",
      confirmationMessageAdditionalDays:
        "You have added {{safeAdditionalDays}} days to this patient's phase, bringing the total from {{initialDays}} to {{totalDays}} days.",
      currentExos: "Exercises in progress",
      currentPhase: "Current phase",
      disabledKine: "💡 You've decided to shorten this phase and move on to the next one.",
      disabled:
        "💡 Your physiotherapist has decided to shorten this phase and move on to the next one.",
      exosTocome: "Upcoming exercises",
      goBackToPhaseMessageKine:
        "💡 You have decided to take your patient back to the previous phase.",
      goBackToPhaseMessage:
        "💡 Your physiotherapist has moved you back to the previous exercise session.",
      infoPatientAdvancePhase:
        "🚀 Your physiotherapist has advanced you to this new exercise session. \n\n Well done, keep up the good work!",
      infoPatientGoBackToPhaseMessage:
        "💡 Your physiotherapist has moved you back to the previous exercise session. \n\n Don't get discouraged, every step is a step towards your recovery!",
      kinePhase: "Phase",
      maxAdditionalDaysMessage:
        "The number of additional days cannot exceed the initial number of days in the phase ({{maxAdditionalDays}} days).",
      modifyAdditionalDays: "Modify additional days",
      modifyAddedDaysInfoKine:
        "💡 The current phase has been extended by {{addedDaysNumber}} days, increasing from {{initialDays}} to {{totalDaysNumber}} days.",
      modifyDays: "Modify the number of days:",
      nextSession: "The next stage of my program begins on\n",
      noExos: "There are no exercises planned at this stage of the program.",
      noNextSession: "My program ends on\n",
      phaseBefore: "Previous phase",
      phaseToCome: "Next phase",
      programEnded: "My program ended on\n",
      programToCome: "My program starts on\n",
      removeDaysInfoKine: "💡 You have removed {{addedDaysNumber}} days to this patient's phase.",
      totalDay: "day",
      totalDays: "days",
      validationRequired: "Validation Required",
      warningError: "An error occurred.\n Please try again.",
      warningMessage:
        "The total number of days must be greater than or equal to the days completed. Please add at least {{minAdditionalDays}} days.",
    },
    protocolInfo: {
      surgeryDate: "My surgery",
      endDate: "💡 My program ends on",
      endedDate: "💡 My program ended on",
      button: "See my protocol",
      seeMore: "See more",
      seeLess: "See less",
      step: "Step",
      programStart: "Start of program",
      noProtocols: "The protocol is not available",
      inProgress: "(in progress)",
    },
    eval: {
      title: "Last evaluation",
      navigation: "My evolution",
      pain: "Pain",
      effort: "Difficulty",
      stiffness: "Stiffness",
      fatigue: "Fatigue",
      noEval: "No data recorded for the moment",
    },
  },
  [MainNS]: {
    tabs: {
      faq: "FAQ",
      codes: "Codes",
      home: "Home",
      account: "Account",
      patients: "Patients",
      protocols: "Protocols",
      journey: "Journey",
      patientJourney: "New",
    },
    header: {
      home: "Home",
      faq: "Frequently Asked Questions",
      placeholder: "...",
      searchByName: "Search by first or last name",
      searchByPostalCode: "Search by postal code",
      codes: "Codes",
      organs: "Choose a joint",
      patients: "Patients",
      patient: "Patient",
      pathologies: "Choose a protocol",
      phaseResume: "Phase information",
      protocols: "Protocol Informations",
      specialties: "Select a Specialty",
      journey: "My journey",
      timelineProtocol: "Program",
      calendar: "Activity tracking",
      graph: "Weekly evaluations",
      name: "Name",
      postal_code: "Postal code",
      searchBy: "Search by :",
    },
  },
  [NavigationNS]: {
    Phone: {
      title: "Phone",
    },
    Address: {
      title: "Work Address",
    },
    ShareContact: {
      title: "Sharing authorization",
    },
    KineSpecialties: {
      title: "Specialties",
      info:
        "💡 Communicate your specialties to facilitate the connection with patients who have specific needs that match your expertise.",
    },
    KineDataScreen: {
      title: "Doct'UP Referencing",
    },
    PatientsRequests: {
      title: "Patient requests",
    },
    SMSValidation: {
      title: "SMS Code",
    },
    Welcome: {
      welcome: [
        {
          title: "DOCT'UP",
          description:
            "A digital tool to better prepare and get more involved in the rehabilitation process.",
        },
        {
          title: "Fulfill administrative tasks",
          description:
            "A simple and effective tool\n" + "so you don't forget anything before the surgery.",
        },
        {
          title: "Daily\n" + "post-operative\n" + "follow-up",
          description:
            "Follow your rehabilitation program every day with videos adapted to your level and progress.",
        },
        {
          title: "A remote monitoring platform designed by healthcare professionals",
          description:
            "Follow your rehabilitation program every day\n" +
            "with videos adapted to his level and progress.",
        },
      ],
    },
    Cgu: {
      title: "Terms Of Use | $t(common:appName)",
    },
    CodeInfo: {
      title: "Share my code",
    },
    Profile: {
      title: "Profile",
    },
    Operation: {
      title: "Medical information",
      surgeries_title: "Surgeries",
      other_pathologies: "Other pathologies",
      heart_disease: "Heart diseases",
      respiratory_disease: "Respiratory disease",
      therapies: "Therapies",
    },
    Intervention: {
      title: "My intervention",
    },
    PracticianList: {
      kine: "Find a physiotherapist",
      surgeon: "Find a surgeon",
    },
    PracticiansRequestedList: {
      title: "Patient Request",
    },
    PracticiansRequestedScreen: {
      title: "Patient request",
    },
    Alert: {
      title: "Alerts",
    },
    Specialty: {
      title: "What service do you need?",
    },
    Organ: {
      title: "Articulation",
    },
    OrganOnco: {
      title: "Location",
    },
    Pathology: {
      title: "Pathology",
    },
    Parameters: {
      title: "Customization",
      info: "Here, you can choose to show or hide certain information for your patients.",
      promsActivation: "Enable Display of PROMS",
      loading: "Updating your settings, this will just take a moment.",
      promsInfo:
        "💡 PROMS are surveys sent at various stages of the treatment to better monitor your patients' progress.",
      todosActivation: "Enable Display of Todos",
      parametersTitle: "PROMS and Todos",
      todosInfo:
        "💡 Todos are task lists assigned at different stages of the treatment to assist your patients in preparing and following their protocol.",
    },
    Applications: {
      dialog: {
        label: "I understand",
        title: "💡 Information",
        proms:
          "This application offers patients Proms.\nYou can disable those proposed by Doct'UP in this tab.",
        all:
          "This application offers patients Proms and Todos.\nYou can disable those proposed by Doct'UP in this tab.",
      },
      title: "📱 Applications",
      enable: "Enable",
      disable: "Disable",
      infoMobile:
        "Are you using one of these applications?\nYou can activate redirect links to these platforms at any time on the web.",
      infoWeb:
        "Are you using one of these applications?\nYou can activate redirect links to these platforms at any time.",
      linkInfo:
        "💡 Once activated, you'll find the redirection links only on our application's web interface.",
    },
    Practitioner: {
      title: "Practician",
    },
    Research: {
      title: "Research Data",
    },
    Language: {
      title: "Language",
    },
    DeleteAccount: {
      title: "Delete account",
      cancel: "Cancel",
      confirmationDeletion:
        "You have requested to delete your account. This deletion will be effective in the coming days, an email will be sent to you as soon as the deletion is effective.\n ",
      definitiveDeletionTitle: "The deletion of your account is permanent.",
      definitiveDeletionParagraph:
        "All data related to your account will be deleted permanently and cannot be retrieved.\n Would you like to proceed?",
    },
    ReviewUrl: {
      title: "Automatic notifications",
    },
    RdvUrl: {
      title: "Appointment site",
    },
    SurgeonPractice: {
      title: "My practice",
      question: "How many patients do you plan to distribute DoctUp to each month?",
      info:
        "This information is used to improve your experience by personalizing the number of codes sent to you.",
      junior: "Between 0 and 29",
      intermediate: "Between 30 and 49",
      senior: "More than 50+",
      unknown: "I do not wish to answer",
    },
    MedicalInfos: {
      title: "Medical information",
    },
    MedicalTeam: {
      title: "Medical team",
    },
  },
  [OperationNS]: {
    header:
      "Enter your medical information to enable the medical team to monitor your progress.\nIf in doubt, contact your surgeon.",
    informationWithoutCode: "💡 Share your code with your medical team for better monitoring.\n",
    myCode: "🔑 MY CODE : {{code}}",
    seeMore: "Learn more",
    pathologyInformation:
      "💡 Your health data is essential for establishing a personalized rehabilitation protocol. If in doubt, contact your surgeon.",
    pathologyEditInformation:
      "💡 To update information related to your condition, contact our customer support:",
    editMedicalTeam:
      "💡 If you no longer wish to be followed by one of the members of the medical team, please contact our customer support:",
    supportButton: "contact@doctup.fr",
    editMedicalTeamTitle: "[Doct'up] - Update my medical team",
    editMedicalTeamText:
      "Hello,\n\nI no longer wish to be followed by the healthcare professional: \n\n\nUser : {{firstName}}·{{lastName}}\nID : {{id}}",
    contactTitle: "[Doct'up] - Pathology error",
    contactText: "User : {{firstName}}·{{lastName}}, ID : {{id}}",
    missing: "Not registered",
    requestSent: "Request in progress",
    label: {
      surgeon: "Surgeon",
      surgeryDate: "Surgery date",
      pathology: "Pathology",
      kine: "Physiotherapist",
      gp: "Physician",
    },
    noProgram: "There is no available program, please contact us.",
    required: {
      kine: "Only letters are accepted.",
      pathology: "A pathology must be selected.",
      surgeryDate: "Date of surgery is mandatory",
    },
    findKine: {
      text:
        "💡 So that your physiotherapist can track your progress and adjust your treatment plan,",
      button: "Add them here",
    },
    rdvButton: "Schedule an appointment",
    seeRequests: {
      text: "You have sent a follow-up request for the physiotherapist {{firstName}} {{lastName}}",
      button: "See my request",
    },
  },
  [PatientsListNS]: {
    all: "All",
    new: "NEW",
    pathology: "{{name}}",
    add: "Add a patient",
    name: "{{firstName}} {{lastName}}",
    daySinceSurgery: "Jour {{current}}/{{lastDay}}",
    daySinceSurgery_positive: "D+{{nbDays}}",
    daySinceSurgery_negative: "D{{nbDays}}",
    ended: "Terminé",
    therapy_data: "{{therapy_data}}",
    surgery_data: "{{surgery_data}} surgeries",
    other_path_data: "{{other_path_data}} pathologies",
    date_unknown: "Fill in the date",
    no_onco_data: "No",
  },
  [PatientNS]: {
    additionalInformation: "Additional info",
    additionalDays: "Number of additional days: ",
    bipolar: "Bipolar sensor",
    empty: "No messages",
    errors: {
      load_patient: "Select a patient",
      send_message: "An error occurred while sending the message",
    },
    subtitle_negative: "D{{nbDays}} •",
    subtitle_positive: "D+{{nbDays}} •",
    storeReview: {
      title: "Rate Doct'Up",
      message: "If you like Doct'Up, please give us your feedback!",
      decline: "No, thank you",
      accept: "Yes, I do",
    },
    effort: "Effort",
    pain: "Pain",
    difficulty: "Difficulty",
    stiffness: "Stiffness",
    diligence: "Assiduity",
    information: "Informations",
    handlePhases: "Adapt protocol",
    viewPhases: "Protocol phases",
    proms: "PROMs evaluations",
    PREOPERATIVE: "Preoperative",
    SIX_POSTOPERATIVE: "From 0 to 6 months post-op",
    TWELVE_POSTOPERATIVE: "From 6 to 12 months post-op",
    ONE_YEAR_POSTOPERATIVE: "More than 1 year post-op",
    rating: "Satisfaction",
    score: "{{value}}%",
    surgeon: "Surgeon",
    surgeryDate: "Surgery date",
    surgeryDuration: "Surgery duration",
    surgeryAdditionalInfo: "Special surgical condition",
    toFill: "To fill",
    kine: "Physiotherapist",
    gp: "General practitioner",
    addCr: "Add a medical report",
    label: {
      yes: "Yes",
      no: "No",
    },
    radio: {
      support: "Supports",
      complication: "Complication",
      splint: "Splint",
      meniscal: "Meniscal suture",
    },
    evaluation: "Last evaluation",
    progression: "Progression",
    patientProgression: "My protocol",
    activityTracking: "Activity tracking",
    phase: "Phase {{index}}",
    report: "Medical report sent",
    weekEvaluation: "Weekly reports",
    messages: "Messages",
    message: "Message",
    addMessage: "Add a message",
    send: "Send",
    journeyCongratulation: "Congratulations 👏",
    postOpDaySinceBegining: "It's your {{nbDays}}th day of rehabilitation!",
    postOpFirstDaySinceBegining: "It's your {{nbDays}}st day of rehabilitation!",
    preOpDaySinceBegining: "💡 There are {{nbDays}} days left before your operation.",
    surgeryDay: "It's your surgery day 💪",
    numberOfSessions: "You have achieved\n",
    numberOfSessionsEnd: "\ndays of activity",
    programEnd: "💡 Your program will end on the {{endDate}}",
    newPhaseStart: "New exercise\nsession on\n{{newPhase}}",
    noNewPhase: "You have no upcoming exercise session.",
    noEvaluationYet: "No evaluation filled in yet.",
    usefulInformation: "Useful information",
    alerts: [
      { key: 0, title: "Add an alert - optional" },
      { key: 1, title: "Physical therapy" },
      { key: 2, title: "Scar" },
      { key: 3, title: "Pain" },
      { key: 4, title: "Temperature" },
      { key: 5, title: "Stiffness" },
    ],
    painChartTitle: "Pain",
    effortChartTitle: "Difficulty",
    noData: "No data recorded for the moment",
    loadingData: "Loading",
    errorData: "An error occurred while loading the data",
    lastSession: "Last session",
    noActivityRecorded: "No activity recorded",
    rdvLink: "Schedule an appointment",
    followRequestInfo: {
      text: "💡 Your follow-up request with a physiotherapist is pending their approval.",
      button: "View my request",
    },
    practicianListLink: {
      kine: "Link to this physiotherapist",
      surgeon: "Link to this surgeon",
      requestSent: "✔️ Request sent",
      cancelRequest: "Cancel request",
    },
    practicianListModal: {
      kine: {
        title: "Link to this physiotherapist",
        text:
          "By confirming that {{lastName}} {{firstName}} is your physiotherapist, a confirmation request will be sent to them.\nOnce they have validated it, they will be linked to your account and will be able to monitor your progress.",
        success:
          "Your request has been successfully sent to the physiotherapist {{lastName}} {{firstName}}.",
        cancelTitle: "Cancel my follow-up request",
        cancelText:
          "Are you sure you want to cancel your follow-up request for the physiotherapist {{lastName}} {{firstName}}?",
        cancelSuccess:
          "Your follow-up request for the physiotherapist {{lastName}} {{firstName}} has been successfully canceled.",
      },
      surgeon: {
        title: "Link to this surgeon",
        text:
          "By confirming that {{lastName}} {{firstName}} is your surgeon, a confirmation request will be sent to them.\nOnce they have validated it, they will be linked to your account and will be able to monitor your progress.",
        success: "Your request has been successfully sent to the Dr {{lastName}} {{firstName}}.",
        cancelTitle: "Cancel my follow-up request",
        cancelText:
          "Are you sure you want to cancel your follow-up request for the Dr {{lastName}} {{firstName}}?",
        cancelSuccess:
          "Your follow-up request for the Dr {{lastName}} {{firstName}} has been successfully canceled.",
      },
      validate: "Validate",
      cancel: "Cancel",
      error: "An error occurred while sending your request. Please try again later.",
      cancelError: "An error occurred while canceling your request. Please try again later.",
    },
    notInList: {
      kine: {
        title: "My therapist isn't on the list",
        text:
          "Your physiotherapist is not registered with Doct'UP, so they will not be able to track your progress. Feel free to tell them about our app; they will be able to follow you for free!",
      },
      surgeon: {
        title: "My surgeon is not on the list.",
        text:
          "Your surgeon is not registered with Doct'UP, so they will not be able to track your progress. Feel free to tell them about our app; they will be able to follow you for free!",
      },
      validate: "Iunderstand",
    },
    listFilter: "Filter",
    kineLinkedModal: {
      title: "Your physiotherapist has accepted your request 🎉",
      text:
        "The physiotherapist {{kineName}} has accepted your request and can now provide your follow-up care.",
      validate: "I understand",
    },
    kineNotLinkedModal: {
      title: "Physiotherapist not confirmed",
      text:
        "The physiotherapist you selected does not acknowledge following you.\n\nWould you like to select another physiotherapist?",
      validate: "Yes",
      cancel: "See later",
    },
    surgeonLinkedModal: {
      title: "Surgeon follow-up confirmed",
      text: "💡 The surgeon you selected is now handling your follow-up!",
      validate: "I understand",
    },
    surgeonNotLinkedModal: {
      title: "Surgeon not confirmed",
      text:
        "The surgeon you selected does not acknowledge following you.\n\nWould you like to select another surgeon?",
      validate: "Yes",
      cancel: "See later",
    },
    specialty: "💡 Specialty",
    specialties: "💡 Specialties",
    noPracticiansAround: {
      kine: "There are no Doct'Up physiotherapists located within 50 km of your current location.",
      surgeon: "There are no Doct'Up surgeons located within 50 km of your current location.",
    },
    noPracticiansFound: {
      kine: "No Doct'Up physiotherapist matches your search.",
      surgeon: "No Doct'Up surgeon matches your search.",
    },
  },
  [PractitionerHomeNS]: {
    codes: "Codes",
    patients: "Patients",
    button: {
      activate: "Activate codes",
      order: "Order codes",
    },
    empty: "No patient",
    subtitle: {
      order: "I've used all the codes in my code pack and would like to order a new one.",
      activate: "I have received a codes pack and would like to activate it.",
      review: "Improve your online visibility by automatically asking your patients for feedback.",
      rdvUrl:
        "💡 Make it easier for your patients by sharing the link to your online appointment booking site.\n\nYou can copy this link directly from your reservation platform. Ensure that it correctly redirects to the appointment booking page.",
    },
    activate: "Activated codes. You can now distribute them to your patients.",
    patientAdded: "Patient successfully added",
    activatedPack:
      "Pack activated. Your patients will appear in your list once their account is created.",
    order: {
      subject: "Ordering codes",
      body: "{{firstName}}·{{lastName}}·{{phone}}\n" + "Number of codes needed (10, 50, 100):",
    },
    review: {
      title: "Automatic notifications",
      label: "Link to the notification form",
      button: "Where to find the link ?",
      switch: "Enable automatic notifications",
    },
    rdvUrl: {
      label: "Appointment site link",
      error: "Invalid URL format",
      info: "Example : https://www.example.com",
    },
    validateRequest: "⚠️ You have {{requestsNumber}} patient follow-up request to validate.",
    validateRequests: "⚠️ You have {{requestsNumber}} patient follow-up requests to validate.",
    noPatientsRequests: "💡 You have no patient requests to validate.",
    noPracticianRequests: "💡 You have no follow-up requests.",
    requestButtons: {
      validate: "Approve",
      cancel: "Reject",
    },
    validateRequestsModal: {
      title: "New patient request",
      text: "You have a new patient follow-up request.\nWould you like to process it?",
      multipleTitle: "New patient requests",
      multipleText: "You have new patients follow-up requests.\nWould you like to process them?",
      seeList: "View request",
      multipleSeeList: "View requests",
      later: "View later",
    },
    validateFollowModal: {
      title: "Approve",
      text:
        "By approving the follow-up for patient {{firstName}} {{lastName}}, they will automatically appear in your patient list!",
      seeList: "Approve",
      later: "Cancel",
      validate: "Validate",
      cancel: "Cancel",
      success: "You can now find patient {{firstName}} {{lastName}} in your patient list!",
      error: "An error occurred while approving the follow-up. Please try again later.",
    },
    rejectFollowModal: {
      title: "Reject follow-up",
      text: "Are you sure you don’t want to follow patient {{firstName}} {{lastName}}?",
      seeList: "Reject follow-up",
      later: "Cancel",
      validate: "Reject",
      cancel: "Cancel",
      success: "The follow-up request has been successfully canceled.",
      error: "An error occurred while canceling the follow-up request. Please try again later.",
    },
    userAge: "🎂 Age: {{patientAge}} years",
    surgeryDate: "🗓️ Surgery date : ",
    surgeonName: "🧑🏻‍⚕️ Surgeon : ",
  },
  [PraticienNS]: {
    surgeon: "Surgeon",
    kine: "Physiotherapist",
    gp: "General practitioner",
  },
  [ProfileNS]: {
    title: "Profile",
    label: {
      cm: "cm",
      kg: "kg",
      size: "Size",
      weight: "Weight",
      lastName: "Last name",
      firstName: "First name",
      male: "Male",
      gender: "Gender",
      female: "Female",
      birthdate: "Birth date",
    },
    required: {
      lastName: "Last name is mandatory",
      firstName: "First name is mandatory",
      size: "Size is mandatory",
      weight: "Weight is mandatory",
      birthdate: "The date must be less than or equal to the current date",
      numeric: "The entry must be a number",
      email: "The email format is not valid",
      surgeonPractice: "This information is mandatory",
      street_number: "The street number is mandatory",
      route: "The route is mandatory",
      postal_code: "The postal code is mandatory",
      locality: "The city is mandatory",
      country: "The country is mandatory",
    },
    address: {
      errorTitle: "⚠️ Error",
      addressError:
        "An error occurred during the recording.\nPlease check your information and try again.\nIf you decide to fill out this form later, your information will not be saved.",
      addressModal: {
        title: "Confirm address",
        message:
          "Is your address:\n\n{{street_number}} {{route}},\n{{locality}} {{postal_code}},\n{{country}}",
        positive: "Yes, I confirm",
        negative: "Modify address",
        later: "Fill in later",
      },
    },
  },
  [ProtocolNS]: {
    faqpreop: "FAQ before surgery",
    faqpostop: "FAQ after surgery",
    phasesList: "Protocol phases",
  },
  [SettingsNS]: {
    address: "Work Address",
    kineSpecialties: "Specialties",
    KineDataScreen: "Doct'UP Referencing",
    PatientsRequests: "Patient requests",
    automatic_review: "Automatic notifications",
    rdv_link: "Appointment site",
    profile: "Profile",
    medicalInfo: "Medical information",
    alert: "Alerts",
    code: "Activation code",
    phoneNumber: "Phone number",
    parameters: "Customization",
    applications: "My applications",
    support: "Customer support",
    surgeonPractice: "Surgeon practice",
    logout: "Logout ?",
    disconnect: "Are you sure you want to log out of your account ?",
    confirm: "Yes, disconnect",
    research: "Research Data",
    deleteAccount: "Delete account",
    operations: "Operations",
    therapies: "Therapies",
    radiotherapy: "Radiotherapy",
    chemotherapy: "Chemotherapy",
    hormoneTherapy: "Hormone Therapy",
    immuno: "Immuno Therapy",
    othersPathology: "Others Pathology",
    heart_disease: "Heart disease",
    respiratory_disease: "Respiratory disease",
    endocrine_disease: "Endocrine disease",
    mastectomy: "Mastectomy",
    tumorectomy: "Tumorectomy",
    sentinel_node: "Sentinel node surgery",
    ax_curing: "Axillary curage",
    reconstructive: "Reconstructive surgery",
    hypertension: "Hypertension",
    heart_rhythm: "Cardiac rhythm disorders",
    infarction: "Myocardial infarction",
    other: "Other",
    cerebral: "Cerebral vascular accident",
    bronchitis: "Chronic bronchitis",
    asthma: "Asthma",
    lung_cancer: "Lung cancer",
    language: "Language",
    shareContact: "Sharing authorization",
    surgeryInfos: "My intervention",
    medicalTeam: "My medical team",
  },
  [SMSValidationNS]: {
    sent: "Code sent",
    networkError: "Network error",
    codeError: "Wrong validation code",
    "auth/too-many-requests":
      "We have blocked all requests from this device due to unusual activity. Please try again later.",
    "auth/invalid-verification-code": "The verification code is invalid.",
    "auth/invalid-credential": "Your phone number has not been properly validated.",
    "auth/credential-already-in-use":
      "This phone number is already associated with another user account.",
    "auth/cancelled-popup-request": "error",
    unknown: "An error occurred while checking the code.",
  },
  [PromsNS]: {
    satisfaction: "Satisfaction",
    score: "Final score",
    notShow: "Not show again",
    later: "Do it later",
    notShare: "Do not share",
    share: "Share your review",
    doneTitle: "{{title}}\n" + "You have already completed this form",
    endTitle: "{{name}}'s score : {{score}}",
    endSubtitle: "Thank you for your answer !",
    shareTitle: "Thank you very much !",
    shareSubtitle:
      "Would you like to help your medical team gain visibility by sharing this online review ?",
    notShareSubtitle: "Your feedback will help us improve patient care.",
    placeholder: "Add a text",
    step: "Step {{current}}/{{total}}",
    error: "Unable to connect",
    "auth/invalid-id-token": "This form is for another patient",
    close: "You can close this tab in your browser",
    oncoTitle: "Health information",
    oncoSubtitle:
      "We will first ask you a few questions to learn more about your living situation and health.\n" +
      " \n" +
      "Each page of the questionnaire will be saved as soon as you move on to the next page.\n- You can complete the questionnaire in several times.\n- You can make changes at any time.",
    noChirProgram:
      "No reeducation program was found for the surgery: {{surgery_type}} on date : {{date}}",
  },
  [LanguageNS]: {
    title: "Select your language",
    name: {
      fr: "French",
      en: "English",
    },
  },
  [VideoNS]: {
    title: "Let's start with DoctUp!",
    button: "Start the experience",
    subtitle:
      "Explore DoctUp's features in our introductory video. Discover how rehabilitation becomes simple and personalized.",
    encourageText:
      "Dive into our DoctUp presentation video now! If you're short on time, find it in our FAQ section for later viewing",
  },
}
