import {
  Body2,
  Button,
  functions,
  IUserContext,
  logger,
  openUrl,
  Subtitle1,
  Subtitle2,
  useAlert,
  userContext,
  useTheme,
  VectorIcon,
} from "capsule"
import _ from "lodash"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet, View } from "react-native"

import { maxWidth } from "../../capsule/features/Theme/dimensions"
import { OnUpdatePatientsRequests } from "../../common/types"
import { ICON_SIZE } from "../../features/config/Constants"
import { itemField } from "../../features/hooks/useItemI18n"
import { PatientNS } from "../../features/i18n/constants"
import { AppUserData, Practician } from "../../features/models/UserData"

interface Organ {
  id: string
  i18n: {
    de: Record<string, any>
    en: Record<string, any>
    fr: Record<string, any>
  }
}

interface IProps {
  item: Practician
  role: string
  organs: Organ[]
  setIsLoading: (isLoading: boolean) => void
}

const PracticianItem: FC<IProps> = React.memo(({ item, role, organs, setIsLoading }) => {
  const { userData, user } = useContext<IUserContext<AppUserData>>(userContext)
  const { t } = useTranslation(PatientNS)
  const { showDialog, onDismiss, showSnack } = useAlert()
  const teamArray = userData?.medicalTeamInfo
    ? Object.entries(userData?.medicalTeamInfo).map(([id]) => id)
    : []
  const isPatientPractician = teamArray.length > 0 && _.includes(teamArray, item.id)
  const isPatientsRequests = item.patientsRequests && _.includes(item.patientsRequests, user?.uid)
  const practicianRequested = userData?.practiciansRequested
  const showItem =
    !practicianRequested ||
    practicianRequested.length === 0 ||
    (practicianRequested.length > 0 && isPatientsRequests)
  const filteredOrgans = useMemo(() => {
    const filtered = organs
      .filter(organ => item.kineSpecialties.includes(organ.id))
      .map(organ => ({
        i18n: {
          en: { specialty: organ.i18n.en.specialty },
          fr: { specialty: organ.i18n.fr.specialty },
        },
      }))
    const uniqueFilteredOrgans = Array.from(
      new Set(filtered.map(uniqueFilter => JSON.stringify(uniqueFilter.i18n))),
    ).map(uniqueI18n =>
      filtered.find(uniqueFilter => JSON.stringify(uniqueFilter.i18n) === uniqueI18n),
    )
    return uniqueFilteredOrgans as typeof filtered
  }, [organs, item.kineSpecialties])
  const {
    fontMaker,
    dimensions: { spacing },
    colors: {
      accent,
      primary,
      white: { highEmphasis: white },
      black: { highEmphasis: black },
    },
    typography: { caption },
  } = useTheme()
  const s = useMemo(
    () => ({
      cardView: [
        styles.body,
        {
          padding: spacing,
          marginHorizontal: spacing,
          marginVertical: spacing / 2,
          backgroundColor: isPatientPractician ? primary : accent,
        },
      ],
      nameText: [
        fontMaker({ weight: "Bold" }),
        {
          color: isPatientPractician ? white : black,
        },
      ],
      specialtyText: {
        color: isPatientPractician ? white : black,
      },
      addressView: [
        styles.addressView,
        {
          marginVertical: spacing / 2,
        },
      ],
      distanceView: [
        styles.addressView,
        {
          marginVertical: spacing / 4,
        },
      ],
      addressText: {
        paddingLeft: spacing / 4,
        fontSize: caption.fontSize,
        lineHeight: caption.lineHeight,
        color: isPatientPractician ? white : black,
      },
      contactLink: [fontMaker({ weight: "Bold" }), styles.contactLink],
      dialog: [
        styles.dialog,
        {
          paddingHorizontal: spacing,
        },
      ],
      requestSentText: [
        styles.requestSentText,
        fontMaker({ weight: "Bold" }),
        {
          padding: spacing / 2,
        },
      ],
    }),
    [
      spacing,
      accent,
      fontMaker,
      black,
      caption.fontSize,
      caption.lineHeight,
      isPatientPractician,
      primary,
      white,
    ],
  )

  const updatePatientsRequestsArray = useCallback(
    async (isPatientCancel?: boolean) => {
      setIsLoading(true)
      try {
        if (user?.uid) {
          const params: OnUpdatePatientsRequests = {
            practician: item.id,
            patient: user?.uid,
            practicianMail: item.email,
            practicianLng: item.lng,
            patientName: `${userData?.firstName} ${userData?.lastName}`,
            isCancel: isPatientCancel,
          }
          await functions().httpsCallable("updatePatientsRequests")(params)
          showSnack({
            message: t(
              isPatientCancel
                ? `practicianListModal.${role}.cancelSuccess`
                : `practicianListModal.${role}.success`,
              {
                ns: PatientNS,
                lastName: item.lastName,
                firstName: item.firstName,
              },
            ),
          })
          setIsLoading(false)
        }
      } catch (e) {
        logger("Request error", e)
        showSnack({
          message: t(
            isPatientCancel ? `practicianListModal.cancelError` : `practicianListModal.error`,
          ),
        })
        setIsLoading(false)
      }
    },
    [role, showSnack, t, user?.uid, setIsLoading, item, userData?.firstName, userData?.lastName],
  )

  const onPress = useCallback(
    (isPatientCancel?: boolean) => {
      showDialog({
        type: "button",
        style: s.dialog,
        contentContainerStyle: styles.dialogContent,
        title: isPatientCancel
          ? t(`practicianListModal.${role}.cancelTitle`)
          : t(`practicianListModal.${role}.title`),
        message: t(
          isPatientCancel
            ? `practicianListModal.${role}.cancelText`
            : `practicianListModal.${role}.text`,
          {
            ns: PatientNS,
            lastName: item.lastName,
            firstName: item.firstName,
          },
        ),
        positive: {
          onPress: () => {
            onDismiss()
            updatePatientsRequestsArray(isPatientCancel)
          },
          label: t(`practicianListModal.validate`),
          labelType: "cancel",
        },
        negative: {
          label: t(`practicianListModal.cancel`),
        },
      })
    },
    [
      s.dialog,
      showDialog,
      t,
      item.firstName,
      item.lastName,
      role,
      updatePatientsRequestsArray,
      onDismiss,
    ],
  )

  return (
    <>
      {showItem ? (
        <View style={s.cardView}>
          <View>
            <View style={styles.headerView}>
              <Subtitle1 style={s.nameText}>
                {item.firstName} {item.lastName}
              </Subtitle1>
              {item.distance ? (
                <View style={s.distanceView}>
                  <VectorIcon
                    name="near-me"
                    category="MaterialIcons"
                    size={ICON_SIZE / 1.5}
                    color={isPatientPractician ? white : black}
                  />
                  <View style={styles.addressTextView}>
                    <Subtitle2 style={s.addressText}>{item.distance}</Subtitle2>
                  </View>
                </View>
              ) : null}
            </View>
            <View style={s.addressView}>
              <VectorIcon
                name="location-on"
                category="MaterialIcons"
                size={ICON_SIZE / 1.5}
                color={isPatientPractician ? white : black}
              />
              <View style={styles.addressTextView}>
                <Subtitle2 style={s.addressText}>
                  {item.address.street_number} {item.address.route} {item.address.postal_code}{" "}
                  {item.address.locality}
                </Subtitle2>
              </View>
            </View>
            {filteredOrgans && filteredOrgans.length > 0 ? (
              <Subtitle2 style={s.nameText}>
                {filteredOrgans.length === 1 ? t("specialty") : t("specialties")}
              </Subtitle2>
            ) : null}
            {filteredOrgans && filteredOrgans.length > 0
              ? filteredOrgans.map((organSpecialty: any, index: number) => (
                  <Body2 style={s.specialtyText} key={`${item.id}${index}`}>
                    - {itemField(organSpecialty, "specialty", t)}
                  </Body2>
                ))
              : null}
            {!isPatientPractician ? (
              isPatientsRequests ? (
                <>
                  <Subtitle2 style={s.requestSentText}>
                    {t(`practicianListLink.requestSent`)}
                  </Subtitle2>
                  <Button
                    mode="text"
                    labelStyle={styles.buttonLabel}
                    onPress={() => onPress(true)}
                    contentStyle={styles.buttonContent}
                  >
                    <Body2 style={s.contactLink}>{t(`practicianListLink.cancelRequest`)}</Body2>
                  </Button>
                </>
              ) : (
                <Button
                  mode="text"
                  labelStyle={styles.buttonLabel}
                  onPress={() => onPress()}
                  contentStyle={styles.buttonContent}
                >
                  <Body2 style={s.contactLink}>{t(`practicianListLink.${role}`)}</Body2>
                </Button>
              )
            ) : null}
            {isPatientPractician && item.rdvLink ? (
              <Button
                mode="text"
                labelStyle={styles.buttonLabel}
                onPress={() => openUrl(item.rdvLink)}
                contentStyle={styles.buttonContent}
              >
                <Body2 style={s.contactLink} color={isPatientPractician ? white : primary}>
                  {t("rdvLink")}
                </Body2>
              </Button>
            ) : null}
          </View>
        </View>
      ) : null}
    </>
  )
})

const styles = StyleSheet.create({
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  addressView: {
    flexDirection: "row",
  },
  headerView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addressTextView: {
    flexDirection: "column",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  contactLink: {
    justifyContent: "center",
    textDecorationLine: "underline",
  },
  dialog: {
    alignItems: "center",
  },
  dialogContent: {
    maxWidth,
    width: "100%",
  },
  requestSentText: {
    textAlign: "center",
  },
})

export default PracticianItem
