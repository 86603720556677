import { logger } from "capsule"
import _ from "lodash"
import Geolocation from "react-native-geolocation-service"

import { Practician } from "./UserData"

const normalizedText = (text: string) => _.deburr(_.toLower(text))

export const filteredPracticiansDocs = (
  practicians: Practician[],
  searchInput: string,
  isPostalCodeSelected: boolean,
  country?: string,
): Practician[] => {
  const normalizedSearchInput = normalizedText(searchInput)
  const filteredList =
    practicians &&
    practicians.filter(doc =>
      country
        ? doc.address?.country && doc.address?.country === country && !doc.isTestUser
        : doc.address?.country && !doc.isTestUser,
    )
  return filteredList
    ? isPostalCodeSelected
      ? filteredList.filter(
          item =>
            item.address?.postal_code &&
            _.startsWith(normalizedText(item.address?.postal_code), normalizedSearchInput),
        )
      : filteredList.filter(
          item =>
            normalizedText(item.firstName).includes(normalizedSearchInput) ||
            normalizedText(item.lastName).includes(normalizedSearchInput),
        )
    : []
}

const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371
  const dLat = ((lat2 - lat1) * Math.PI) / 180
  const dLon = ((lon2 - lon1) * Math.PI) / 180
  const a =
    0.5 -
    Math.cos(dLat) / 2 +
    (Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * (1 - Math.cos(dLon))) / 2
  return R * 2 * Math.asin(Math.sqrt(a))
}

export const sortPracticiansByProximity = async (
  practicians: Practician[],
  medicalTeam: string[],
  searchInput: string,
  hasPermission: boolean | null,
): Promise<Practician[]> =>
  new Promise<Practician[]>((resolve, reject) => {
    const sortByMedicalTeamAndDistance = (a: Practician, b: Practician) => {
      const isAInMedicalTeam = medicalTeam.includes(a.id) ? 0 : 1
      const isBInMedicalTeam = medicalTeam.includes(b.id) ? 0 : 1
      if (isAInMedicalTeam !== isBInMedicalTeam) {
        return isAInMedicalTeam - isBInMedicalTeam
      }
      return (a.rawDistance || 0) - (b.rawDistance || 0)
    }
    if (!hasPermission) {
      resolve([...practicians].sort(sortByMedicalTeamAndDistance))
      return
    }
    Geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords
        const maxDistanceKm = 50

        const practiciansWithDistance = practicians
          .filter(
            practician =>
              practician.address?.location?.latitude && practician.address?.location?.longitude,
          )
          .map(practician => {
            const practicianLatitude = practician.address?.location?.latitude
            const practicianLongitude = practician.address?.location?.longitude
            if (practicianLatitude && practicianLongitude) {
              const distanceKm = calculateDistance(
                latitude,
                longitude,
                parseFloat(practicianLatitude),
                parseFloat(practicianLongitude),
              )
              const distanceFormatted =
                distanceKm < 1
                  ? `${Math.round(distanceKm * 1000)} m`
                  : `${distanceKm.toFixed(1)} km`

              return {
                ...practician,
                rawDistance: distanceKm,
                distance: distanceFormatted,
              }
            }
            return practician
          })
          .filter(practician => {
            if (searchInput !== "") {
              return true
            }
            return (practician.rawDistance || 0) <= maxDistanceKm
          })
        if (practiciansWithDistance.length === 0) {
          resolve([])
          return
        }
        resolve([...practiciansWithDistance].sort(sortByMedicalTeamAndDistance))
      },
      error => {
        logger(error)
        reject(error)
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 },
    )
  })
