import { TwoPaneScreen } from "capsule"
import _ from "lodash"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"

import SettingsList from "../../components/SettingsList"
import { SettingsNS } from "../../features/i18n/constants"

const MedicalInfosScreen = () => {
  const { t } = useTranslation()

  const medicalSettingsItems = useMemo(
    () =>
      _.filter([
        {
          name: t(`${SettingsNS}:surgeryInfos`),
          iconName: "info",
          route: "OperationEdition",
        },
        {
          name: t(`${SettingsNS}:medicalTeam`),
          iconName: "group",
          route: "MedicalTeamEdition",
        },
      ]),
    [t],
  )

  return (
    <TwoPaneScreen>
      <SettingsList listKey="medicalSettingsList" listItems={medicalSettingsItems} />
    </TwoPaneScreen>
  )
}

export default MedicalInfosScreen
