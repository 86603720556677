import { useNavigation } from "@react-navigation/native"
import {
  Body2,
  Button,
  Caption,
  firestore,
  generateShadow,
  IRowItemProps,
  ISectionHeader,
  IUserContext,
  ParametersScreen,
  userContext,
  useTheme,
  VectorIcon,
  VectorIconProps,
} from "capsule"
import firebase from "firebase"
import { useFormikContext } from "formik"
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useCollectionData } from "react-firebase-hooks/firestore"
import { useTranslation } from "react-i18next"
import {
  Image,
  ImageSourcePropType,
  SectionListData,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native"

import { images } from "../../assets/images"
import { collections } from "../../common/types"
import SubmitButton from "../../components/SubmitButton"
import { ICON_SIZE, INPUT_HEIGHT } from "../../features/config/Constants"
import useItemI18n from "../../features/hooks/useItemI18n"
import useLocationPermission from "../../features/hooks/useLocationPermission"
import usePermissions from "../../features/hooks/usePermissions"
import { OperationNS } from "../../features/i18n/constants"
import { AppUserData, Practician } from "../../features/models/UserData"
import { OncoFormValues } from "./Constants"
import { OncoParamList } from "./OncoPathologyNavigator"

interface IProps {
  isEdit?: boolean
}

const OncoPathologyScreen: FC<IProps> = ({ isEdit = false }) => {
  const {
    fontMaker,
    colors: {
      accent,
      surface,
      white: { highEmphasis: white },
      black: { mediumEmphasis: black },
      primary,
    },
    dimensions: { spacing },
  } = useTheme()
  const navigation = useNavigation<any>()
  usePermissions(isEdit)
  const { hasPermission, requestLocationPermission } = useLocationPermission()

  useEffect(() => {
    if (hasPermission === false && !isEdit) {
      requestLocationPermission()
    }
  }, [hasPermission, requestLocationPermission, isEdit])
  const { t } = useTranslation(OperationNS)
  const { handleSubmit, values, validateForm, setFieldValue } = useFormikContext<OncoFormValues>()
  const { userData } = useContext<IUserContext<AppUserData>>(userContext)
  const query = useMemo(() => {
    if (userData?.practiciansRequested && userData.practiciansRequested.length > 0) {
      return (firestore()
        .collection(collections.LOGIN)
        .where(
          firestore.FieldPath.documentId(),
          "in",
          userData.practiciansRequested,
        ) as unknown) as firebase.firestore.Query<Practician>
    }
    return null
  }, [userData])
  const [practicianDocs] = useCollectionData<Practician>(query, { idField: "id" })
  const requestedKine = practicianDocs?.find(tm => tm.role === "kine")
  const pathologyName = useItemI18n(
    collections.PATHOLOGIES,
    (userData?.pathology as string) ?? values.pathology,
    "name",
  )
  const [submitLoading, setSubmitLoading] = useState(false)
  const isPracticianRequested =
    userData?.practiciansRequested && userData?.practiciansRequested.length > 0
  const practicianRequestedText = isPracticianRequested ? t("requestSent") : t("missing")
  useEffect(() => {
    validateForm()
  }, [values, validateForm])

  useEffect(() => {
    if (isEdit && userData?.specialty) {
      setFieldValue("specialty", userData.specialty)
    }
  }, [isEdit, setFieldValue, userData?.specialty])

  const s = useMemo(
    () => ({
      header: {
        backgroundColor: white,
        paddingVertical: spacing,
      },
      rowSubitem: [
        styles.row,
        styles.shadow,
        {
          marginTop: spacing / 4,
          paddingLeft: spacing * 2,
        },
      ],
      rowKine: [
        styles.shadow,
        {
          padding: spacing,
        },
      ],
      body: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      icon: {
        tintColor: black,
      },
      rowPatInfo: [
        styles.shadow,
        {
          paddingHorizontal: spacing,
          paddingVertical: spacing / 2,
        },
      ],
      bodyInfo: [
        styles.body,
        {
          padding: spacing,
          backgroundColor: accent,
        },
      ],
      codeView: [fontMaker({ weight: "Bold" }), styles.codeView],
      touchable: [styles.touchable, { backgroundColor: surface.textInput }],
      label: [styles.buttonLabel, { right: spacing / 2 }],
      contactLink: [fontMaker({ weight: "Bold" })],
      buttonContent: [
        styles.buttonContent,
        {
          marginTop: spacing / 2,
        },
      ],
    }),
    [accent, black, spacing, surface.textInput, white, fontMaker],
  )

  const renderIcon = useCallback(
    (name: string) =>
      ({
        name,
        category: "MaterialIcons",
        size: ICON_SIZE,
        color: black,
      } as VectorIconProps),
    [black],
  )

  const renderPathologyRightChild = useCallback(
    () => (
      <View style={styles.pathology}>
        {pathologyName ? (
          <Caption style={styles.caption} emphasis="medium">
            {pathologyName}
          </Caption>
        ) : null}
        <VectorIcon {...renderIcon("chevron-right")} />
      </View>
    ),
    [renderIcon, pathologyName],
  )
  // @ts-ignore
  const sections: Array<SectionListData<IRowItemProps<OncoParamList>, ISectionHeader>> = useMemo(
    () => [
      {
        title: t("header"),
        data: [
          {
            rowStyle: s.rowSubitem,
            name: t("label.pathology"),
            route: isEdit ? undefined : "Organ",
            rightChild: renderPathologyRightChild(),
          },
        ],
      },
      {
        shadows: 1,
        headerStyle: s.header,
        data: [
          {
            rowStyle: s.rowSubitem,
            name: (userData?.surgeonName as string) || t("missing"),
            labelStyle: styles.label,
            label: t("label.surgeon"),
            leftChild: (
              <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
            ),
          },
        ],
      },
      !isEdit
        ? {
            shadows: 1,
            data: [
              {
                rowStyle: s.rowSubitem,
                name: (userData?.kineName as string) || practicianRequestedText,
                labelStyle: styles.label,
                label: t("label.kine"),
                leftChild: (
                  <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                ),
              },
              {
                render: () => (
                  <>
                    {!userData?.kineName ? (
                      !requestedKine ? (
                        <View style={s.rowKine}>
                          <View style={s.body}>
                            <Body2>{t("findKine.text")}</Body2>
                            <Button
                              mode="text"
                              labelStyle={s.label}
                              onPress={() => navigation.navigate("KineList")}
                              contentStyle={styles.buttonContent}
                            >
                              <Body2 style={s.contactLink} color={primary}>
                                {t("findKine.button")}
                              </Body2>
                            </Button>
                          </View>
                        </View>
                      ) : (
                        <View style={s.rowKine}>
                          <View style={s.body}>
                            <Body2>
                              {t("seeRequests.text", {
                                ns: OperationNS,
                                lastName: requestedKine?.lastName,
                                firstName: requestedKine?.firstName,
                              })}
                            </Body2>

                            <Button
                              mode="text"
                              labelStyle={styles.buttonLabel}
                              onPress={() => navigation.navigate("KineRequestedScreen")}
                              contentStyle={styles.buttonContent}
                            >
                              <Body2 style={s.contactLink} color={primary}>
                                {t("seeRequests.button")}
                              </Body2>
                            </Button>
                          </View>
                        </View>
                      )
                    ) : null}
                  </>
                ),
              },
            ],
            title: "",
          }
        : null,
      userData?.gpName && !isEdit
        ? {
            shadows: 1,
            headerStyle: s.header,
            data: [
              {
                rowStyle: s.rowSubitem,
                name: (userData?.gpName as string) || t("missing"),
                labelStyle: styles.label,
                label: t("label.gp"),
                leftChild: (
                  <Image style={s.icon} source={images.custom_surgery as ImageSourcePropType} />
                ),
              },
            ],
          }
        : null,
    ],
    [
      isEdit,
      renderPathologyRightChild,
      s,
      t,
      userData?.surgeonName,
      userData?.kineName,
      userData?.gpName,
      navigation,
      primary,
      practicianRequestedText,
      requestedKine,
    ],
  )

  const filteredSections = sections.filter(item => item !== null)

  const onSubmit = useCallback(async () => {
    setSubmitLoading(true)
    handleSubmit?.()
    setSubmitLoading(false)
  }, [handleSubmit])

  const renderFooterComponent = useCallback(
    () => (
      <SubmitButton<OncoFormValues>
        {...{ onSubmit }}
        syncLoading={submitLoading}
        viewStyle={styles.submit}
      />
    ),
    [onSubmit, submitLoading],
  )

  return (
    <ParametersScreen
      listKey="operationList"
      sections={filteredSections}
      footerComponent={renderFooterComponent()}
    />
  )
}

const styles = StyleSheet.create({
  submit: {
    width: "100%",
  },
  row: {
    height: INPUT_HEIGHT,
  },
  label: {
    paddingBottom: 0,
  },
  touchable: {
    height: INPUT_HEIGHT,
    borderRadius: 0,
  },
  shadow: generateShadow(2) as ViewStyle,
  body: {
    borderRadius: 8,
    overflow: "hidden",
  },
  pathology: {
    flexDirection: "row",
    alignItems: "center",
  },
  caption: {
    width: 150,
  },
  buttonContent: {
    flexDirection: "row-reverse",
  },
  buttonLabel: {
    marginHorizontal: 0,
    alignItems: "flex-start",
  },
  codeView: {
    textAlign: "center",
  },
})

export default OncoPathologyScreen
