import React from "react"
import { Text } from "react-native"

export const renderFormattedText = (text: string) => {
  const formattedText = text.replace(/\\n/g, "\n")
  const textParts = formattedText.split(/(\[b\]|\[\/b\])/g)

  return textParts.map((textPart: string, index: number) => {
    if (textPart === "[b]" || textPart === "[/b]") {
      return null
    } else {
      const isBold = index > 0 && textParts[index - 1] === "[b]"
      return (
        <Text key={index} style={isBold ? { fontWeight: "bold" } : {}}>
          {textPart}
        </Text>
      )
    }
  })
}
